import React, { Component } from "react";
import { HashLink } from 'react-router-hash-link';
import Slider from "react-slick";
import $ from 'jquery';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import NewPopup from "./components/NewPopup";

import AOS from 'aos';
import 'aos/dist/aos.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import frame from "../assets/sec1-frame1.png"
import sec4_img from "../assets/top-list.png"
import sec5_img from "../assets/home-sec5-img.png"
import sec6_img from "../assets/home-sec6-img-n.png"
import sec7_img from "../assets/home-sec7-img.png"
import dogenomic from "../assets/degenomic.svg"
import dot_diagram from "../assets/dot_diagram.png"
import sec4_bg from "../assets/home-bg-sec4.png"
import sec3_bg from "../assets/home-bg-sec3.png"
import long_purple from "../assets/long-purple-blur.png"
import long_blur_blue from "../assets/long-blur-blue.png"
import short_purple from "../assets/short-blur-purple.png"
import short_blue from "../assets/short-blur-blue.png"
import community from "../assets/community_img.png"
import arrow_left from "../assets/arrow-left.svg"
import arrow_right from "../assets/arrow-right.svg"
import bg_vid from "../assets/dog2.mp4"
import long_vid from "../assets/dogs2_long.mp4"
import lab from "../assets/lab.mp4"
import shake from "../assets/shake-2.mp4"
import roll from "../assets/rolling.mp4"
import rotate from "../assets/360.mp4"
import jumping from "../assets/jumping_new.png"
import right_btn_arr from "../assets/right-btn-arrow.svg"
import header_video from "../assets/new/header-video-2.mp4"

import instagramIcon from '../assets/social-ico/instagram-ico.svg';
import linkedInIcon from "../assets/social-ico/linkedin-ico.svg";
import twitterIcon from "../assets/social-ico/twitter-ico.png";

import caret_down from "../assets/caret-down.svg";
import dogonomic from "../assets/dogonomic-h.png";
import dogonomic_diagram from "../assets/dogonomic-v.png";

import home_sec3_img from "../assets/new/home-sec3-img.png";
import launch_img1 from "../assets/new/dna.jpg";
import launch_img2 from "../assets/new/launch-img2.png";
import launch_img3 from "../assets/new/launch-img3.png";
import launch_img4 from "../assets/new/launch-img4.png";
import alpha_img from "../assets/new/alpha-img.png";
import alpha_img2 from "../assets/new/alpha-img2.png";
import alpha_img3 from "../assets/new/alpha-img3.png";
import alpha_img4 from "../assets/new/alpha-img4.png";
import alpha_img5 from "../assets/new/alpha-img5.png";
// import fallinlove_img from "../assets/new/fallinlove-img.png";
import fallinlove_img from "../assets/new/gang.png";
import tddc_img from "../assets/new/tddc-img.svg";
import kudos_img from "../assets/new/kudos-img.png";
import join_diamond from "../assets/new/join-diamond.svg";

// investors
import precedeTechnologiesLogo from "../assets/investors/precede-technologies.png";
import outlierVenturesLogo from "../assets/investors/outlier-ventures.png";
import maxStealthLogo from "../assets/investors/max-stealth.png";
import hawkDigitalInnovationLogo from "../assets/investors/hawk-digital-innovation.png";
import floriLogo from "../assets/investors/flori.png"

import launch_line from "../assets/new/launch-line.png";
import launch_line_active from "../assets/new/launch-line-active.svg";
import arr_right from "../assets/new/arr-right.svg";
import caret_faq from "../assets/new/caret-faq.svg";
import arr_purple_right from "../assets/new/arr-purple-right.svg";
import poin_roadmap from "../assets/new/poin-roadmap.png";
import line_stright from "../assets/new/line-stright.svg";
import left_star_glow from "../assets/new/left-star-glow.png";
import right_star_glow from "../assets/new/right-star-glow.png";

// import amit from '../assets/team-pictures/amit.jpeg';
import avatar from '../assets/team-pictures/avatar.jpeg';
import dan from '../assets/team-pictures/dan.jpeg';
import itay from '../assets/team-pictures/itay.jpeg';
import julian from '../assets/team-pictures/julian.jpeg';
import lok from '../assets/team-pictures/lok.jpeg';
import maciej from '../assets/team-pictures/maciej.jpeg';
import rafi from '../assets/team-pictures/rafi.jpeg';
import rosie from '../assets/team-pictures/rosie.jpeg';
import yakir from '../assets/team-pictures/yakir.jpeg';
import muhammad from '../assets/team-pictures/muhammad.jpeg';
import doriAdar from '../assets/team-pictures/dori-adar.jpeg';
import martinGonzalez from '../assets/team-pictures/martin-gonzalez.jpeg';

// featured on
import forbesLogo from '../assets/featured-on/forbes.png'
// import pilotePodcastLogo from '../assets/featured-on/pilote-podcast.jpg'
import ventureBeatLogo from '../assets/featured-on/venture-beat.svg'
import ynetLogo from '../assets/featured-on/ynet.png'
import startupHubLogo from '../assets/featured-on/startup-hub.png'

import { demoLink, discordLink, twitterLink } from "../Constants";

function afterChangeRoadmap(current) {
  if (current > 0) {
    $('.roadmap-arrow > img:first-child').css('filter', 'grayscale(0)')
  } else {
    $('.roadmap-arrow > img:first-child').css('filter', 'grayscale(1)')
  }
}
function afterChangeAlpha(current) {
  $('.indicator-slider > div').removeClass('active')
  $(`.indicator-${current + 1}`).addClass('active')
}



const settings = {
  className: "slider variable-width",
  dots: false,
  arrows: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  adaptiveHeight: true,
  // afterChange: current => afterChangeRoadmap(current),
  beforeChange: (current, next) => afterChangeRoadmap(current, next),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
      }
    }
  ]
};
const settings2 = {
  className: "slider-alpha",
  dots: false,
  arrows: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  infinite: true,
  afterChange: current => afterChangeAlpha(current),
};
const settings3 = {
  className: "slider-roadmap",
  dots: false,
  arrows: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  afterChange: current => afterChangeRoadmap(current),
  responsive: [
    {
      breakpoint: 1125,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: false,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
      }
    }
  ]
};



export default class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dogs: [],
      loading: false,
      retry: false
    }
    this.team = [
      {
        'name': 'Itay Hasid',
        'pos': 'CEO & Co-Founder',
        'social': {
          'twt': 'https://twitter.com/itay_hasid',
          'link': 'https://www.linkedin.com/in/itayhasid'
        },
        'detail': [
          `Itay is a Serial tech executive and an experienced leader of early-stage B2B and B2C startups including Mobeego; acquired in 2016 not before being awarded the RedDot design award`,
          `Itay is a dog lover and guide dog foster parent, passionate about the metaverse and incredibly curious about the potential for AI-human interactions.`
        ],
        'img': itay
      },
      {
        'name': 'Maciej Miarecki',
        'pos': 'CTO & Co-Founder',
        'social': {
          'twt': 'https://twitter.com/maciejmiarecki',
          'link': 'https://www.linkedin.com/in/maciejmiarecki'
        },
        'detail': [
          `Maciej has a Master of Science in Automation and Robotics, and specializes in software engineering.`,
          `He started his career with Europe’s leading software consulting firm and contributed to numerous high-profile projects. He has eight years of experience as a software-oriented engineer and chief technology officer.`
        ],
        'img': maciej
      },
      // {
      //   'name': 'Amit Yogev',
      //   'pos': 'COO & Co-Founder',
      //   'social': {
      //     'twt': '',
      //     'link': 'https://www.linkedin.com/in/amit-yogev-02083b92'
      //   },
      //   'detail': [
      //     `Amit has a Bachelor of Science in Computer Science with a decade of experience in software quality assurance, cyber security, and data loss prevention.`,
      //     `During his career, besides being a blockchain and NFT guru, Amit has worked for some of the biggest names in the cyber-security industry, such as SentinelOne and McAfee.`
      //   ],
      //   'img': amit
      // },
      {
        'name': 'Dan Stern',
        'pos': 'CMO',
        'social': {
          'twt': '',
          'link': 'https://www.linkedin.com/in/dan-stern-50672a9a'
        },
        'detail': [
          `Dan is an eCommerce entrepreneur with ten years of experience. He is an expert in branding, community building, and content marketing, and has worked with Israel's top leading brands.`
        ],
        'img': dan
      },
      {
        'name': 'Yakir Buaron',
        'pos': 'Concept 3D Artist',
        'social': {
          'instagram': 'https://www.instagram.com/yakirbuaron_/',
          'twt': '',
          'link': 'https://www.linkedin.com/in/yakir-buaron-14728069'
        },
        'detail': [
          `A creative industrial designer and lecturer at the Shenkar College of Engineering, Design, and Art.`,
          `Yakir has broad experience in product development working with teams of all sizes, from startups to large companies.`
        ],
        'img': yakir
      },
      {
        'name': 'Dori Adar',
        'pos': 'Game Designer',
        'social': {
          'instagram': '',
          'twt': '',
          'link': 'https://www.linkedin.com/in/dori-adar-48532b55'
        },
        'detail': [
          `Creative Director, Workshop Facilitator, Keynote Speaker, Game & Product designer.`
        ],
        'img': doriAdar
      },
      // {
      //   'name': 'Julian Gidron',
      //   'pos': 'Business Development & Angel Investor',
      //   'social': {
      //     'twt': '',
      //     'link': 'https://www.linkedin.com/in/julian-gidron-952405150'
      //   },
      //   'detail': [
      //     `Julian is an Angel Investor in early stage startups.`,
      //     `He is an experienced business development executive with a proven track record of implementing scalable marketing strategies for consumer-focused tech products.`
      //   ],
      //   'img': julian
      // },
      // {
      //   'name': 'Rafi Gidron',
      //   'pos': 'Angel Investor',
      //   'social': {
      //     'twt': '',
      //     'link': 'https://www.linkedin.com/in/rafi-gidron-a3855825'
      //   },
      //   'detail': [
      //     `Rafi is an Angel Investor best known for founding and co-CEOing Chromatis Networks, arguably the first Israeli unicorn.`,
      //     `He is also the co-founder and board member of several tech companies across multiple sectors.`
      //   ],
      //   'img': rafi
      // },
      {
        'name': 'Lok Wong',
        'pos': 'Web Computer Graphic Designer',
        'social': {
          'twt': '',
          'link': ''
        },
        'detail': [
          `Lok is a computer graphics designer. He makes our Digital Dogs come to life and spends most of his time in the metaverse.`
        ],
        'img': lok
      },
      {
        'name': 'Martin Gonzalez',
        'pos': '3D Generalist & VFX Artist',
        'social': {
          'twt': '',
          'link': ''
        },
        'detail': [
          // `Martin specializes in creating visual effects and 3D animations.`,
          `Martin is specializing in curating amazing assets and animations, direct & create 3D life-like scenes, an animation child prodigy.`,
        ],
        'img': martinGonzalez
      },
      {
        'name': 'Rosie',
        'pos': 'Full Stack Solidity Developup',
        'social': {
          'twt': '',
          'link': ''
        },
        'detail': [
          `Rosie is a pedigree labrador who grew up at our CEO's home. After failing to complete the guide-dogs academy because she was too friendly, she ended up returning to her beloved foster home and has been living with Itay since then. Maybe she’s smarter than we think?`
        ],
        'img': rosie
      },
      {
        'name': 'Muhammad Naushad',
        'pos': '3D Artist',
        'social': {
          'twt': '',
          'link': ''
        },
        'detail': [
          `Muhammad is an experienced 3D artist, and he makes Digital Dogs look real AF!`
        ],
        'img': muhammad
      },
      {
        'name': 'Khursheed Alam',
        'pos': '3D Animator',
        'social': {
          'twt': '',
          'link': ''
        },
        'detail': [
          `Khursheed is a versatile 3D animator specializing in dog behavior and movement. He is a talented artist with an exquisite attention to details and is gives life and soul to our favorite companions.`
        ],
        'img': avatar
      }
    ]

  }

  alphaSwitch(p) {
    var i = $(p.currentTarget).attr('id').split('-')[1]
    this.slider2.slickGoTo(i - 1)
  }

  goSlide(p) {
    if (p) {
      this.slider3.slickNext();
    } else if (!p) {
      this.slider3.slickPrev();
    }
  }

  collapseFaq(s) {
    $($(s.currentTarget).children()[1]).slideToggle()
    $($($(s.currentTarget).children()[0]).children()[1]).toggleClass('active')
  }

  componentWillUnmount() {
    document.onscroll = () => { }
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    AOS.init({
      throttleDelay: 99,
      duration: 700,
      delay: 50,
      once: true,
      anchorPlacement: 'top-bottom',
      useClassNames: true,
    });

    // add clamp class where needed
    this.team.map((item, index) => {
      var ttt = document.querySelector(`[dtt='${index}']`)
      if (ttt) {
        if (ttt.offsetHeight >= 100 || ttt.children.length > 1) {
          $(`[name='ddt-${index}']`).addClass('clamp')
        }
      }
    })

    // var frameNumber = 0,
    // target = document.getElementById("set-height"),
    // vid = document.querySelector('.bg-vid'),
    // duration = 1.494163;

    // vid.addEventListener('loadedmetadata', function() {
    //   vid.currentTime = duration
    // });


    // function scrollPlay(){ 
    //   var p = ((window.pageYOffset / (target.offsetTop-300)) * 100)
    //   var t = (p / 100) * duration
    //   vid.currentTime = duration - t
    //   window.requestAnimationFrame(scrollPlay);
    // }

    // window.requestAnimationFrame(scrollPlay);

    // TODO Uncomment once we want to support community voting for breed
    // setTimeout(() => {
    //   this.getDog()
    // }, 2000)
  }

  clamp(s) {
    $(s.currentTarget).toggleClass('active')
    var c1 = $(s.currentTarget).children()[1]
    var c2 = $(c1).children()[1]
    // $(c2).toggleClass('clamp')
    $(c2).toggleClass('active')
  }

  getDog() {
    this.setState({
      loading: true,
      retry: false
    });
    fetch("https://4pk3te4hed.execute-api.us-east-1.amazonaws.com/options?limit=5")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            dogs: result,
            loading: false
          });
        },
        (error) => {
          this.setState({
            loading: false,
            retry: true
          });
        }
      )
  }

  teamComponent(item, index) {
    return <div className='home-sec10-itm mobile-c flex-r' name={`ddt-${index}`} onClick={(s) => this.clamp(s)}>
      <img src={item.img} alt="" />
      <div className="flex-c h-left">
        <div
          className="t-20-28 t-700 flex-r just-space"
          style={{
            width: '100%'
          }}
        >
          <div className="flex-c">
            {item.name}
            <span className="white-07">
              {item.pos}
            </span>
          </div>
          <div className="flex-r v-center">

            {
              item.social.instagram &&
              <a
                className="hover-btn cursor"
                href={item.social.instagram}
                style={{
                  marginRight: '12px'
                }}
                target='_blank'
              >
                <img
                  src={instagramIcon}
                  alt="Instagram"
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                />
              </a>
            }

            {
              item.social.twt &&
              <a
                className="hover-btn cursor"
                href={item.social.twt}
                style={{
                  marginRight: '12px'
                }}
                target='_blank'
              >
                <img
                  src={twitterIcon}
                  alt="Twitter"
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                />
              </a>
            }

            {
              item.social.link &&
              <a
                className="hover-btn cursor"
                href={item.social.link}
                target='_blank'
              >
                <img
                  src={linkedInIcon}
                  alt="LinkedIn"
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                />
              </a>
            }

            <img
              src={caret_down}
              alt=""
              style={{
                marginLeft: '30px',
                width: '14px',
                height: '14px',
              }}
            />
          </div>
        </div>
        <div className="home-team-desc" dtt={index}>
          {item.detail.map((item2, index2) => (
            <p className={item2.length > 200 ? "t-16-22 white-07 dot-r long" : "t-16-22 white-07 dot-r"}>
              {item2}
            </p>
          ))}
        </div>
      </div>
    </div>
  }

  render() {
    require('../css/homepage.css')
    return (
      <>
        <Navbar walletAddress={this.props.walletAddress} setWalletAddress={this.props.setWalletAddress}></Navbar>

        <NewPopup></NewPopup>

        <div className="section home-sec1 flex-c h-center">
          <video
            data-aos="fade-in"
            data-aos-delay="650"
            className="bg-vid"
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
          >
            <source src={header_video} type='video/mp4' />
          </video>
          <div className="container flex-c h-center">

            <h1 data-aos="fade-up" data-aos-delay="50" className="home-sec1-h1 t-center t-700 t-40-52 no-margin">
              AI Digital Dogs&trade; For Immersive Realities
            </h1>

            <div
              data-aos="fade-up"
              data-aos-delay="150"
              className="t-500 t-18-25"
              style={{
                marginTop: '24px',
                color: 'rgba(224, 224, 224, 1)',
                // marginBottom: '48px',
                textAlign: "center"
              }}>
              Your ideal companion across virtual worlds, social apps, games and more.
            </div>

            <a
              className="purple-btn cursor flex-r v-center hover-btn t-20-28 t-700"
              data-aos="fade-up"
              data-aos-delay="250"
              href="#demo"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              Try Our Demo  &#128062;
            </a>

            {/* <h1 data-aos="fade-up" data-aos-delay="50" className="home-sec1-h1 t-center t-700 t-48-57 no-margin">
              AI Digital Dogs For The Metaverse
            </h1> */}

            {/* <div data-aos="fade-up" data-aos-delay="150" className="t-500 t-18-25" style={{
              marginTop: '24px',
              color: 'rgba(224, 224, 224, 1)',
              marginBottom: '48px'
            }}>
              Let your digital companion know you’re waiting for them
            </div> */}


            {/* <div data-aos="fade-up" data-aos-delay="200" className="t-700 t-24-33" style={{
              marginBottom: '32px'
            }}>
              Join The Whitelist Raffle!
            </div> */}

            {/* <div data-aos="fade-up" data-aos-delay="450" className="home-sec1-sub-email flex-r h-center">
              <input placeholder="Your email.." className="t-18-25" />
              <button className="cursor t-20-28 t-700 hover-btn">
                Get early access
              </button>
            </div> */}

          </div>
        </div>

        <div
          className="section flex-c h-center"
          style={{
            paddingTop: "128px",
            zIndex: 10
          }}
        >
          <h2
            className="t-20-28"
            style={{
              color: "white"
            }}
          >
            Featured on
          </h2>
          <div
            className="featuredOn"
          >

            <a
              href="https://www.forbes.com/sites/charliefink/2022/04/14/this-week-in-xr-epic-epic-raise-a-billion-dollar-avatar-shoppe-remio-to-gamify-vr-training/amp/"
              target="_blank"
            >
              <img
                alt="Forbes"
                data-aos="fade-in"
                data-aos-delay="250"
                src={forbesLogo}
                style={{
                  maxHeight: '35px'
                }}
              />
            </a>

            <a
              href="https://venturebeat.com/2022/04/12/the-digital-pets-company-launches-crypto-dogs-for-the-metaverse/"
              target="_blank"
            >
              <img
                alt="VentureBeat"
                data-aos="fade-in"
                data-aos-delay="350"
                src={ventureBeatLogo}
                style={{
                  maxHeight: '20px'
                }}
              />
            </a>

            <a
              href="https://www.ynetnews.com/business/article/sk22e11xe5"
              target="_blank"
            >
              <img
                alt="Ynet"
                data-aos="fade-in"
                data-aos-delay="450"
                src={ynetLogo}
                style={{
                  maxHeight: '25px'
                }}
              />
            </a>

            <a
              href="https://www.startuphub.ai/startup-launches-3d-ai-driven-crypto-digital-dogs-for-the-metaverse/"
              target="_blank"
            >
              <img
                alt="StartupHub.ai"
                data-aos="fade-in"
                data-aos-delay="550"
                src={startupHubLogo}
                style={{
                  maxHeight: '50px'
                }}
              />
            </a>

          </div>
        </div>

        {/* <div id="set-height"></div> */}

        <div className="section home-sec2 flex-c h-center">
          <img src={short_blue} alt="" className="m-hide" style={{
            position: 'absolute',
            top: '-1080px',
            transform: 'rotate(-7deg)'
          }} />
          <div className="container mobile-c flex-r v-center">

            <div className="left-block">
              <h2 data-aos="fade-up" data-aos-delay="50" className="t-700 t-40-52 no-margin">
                The world’s first
                <br />Digital Dogs&trade;
              </h2>
              <p data-aos="fade-up" data-aos-delay="250" className="t-20-28 t-700">
                Once, an anon scientist had a vision. His mind wandered into the void of just how endless the metaverse is and how living in such an enormous space would be heartbreaking without a companion to share it.
              </p>
              <p className="t-18-25" data-aos="fade-up" data-aos-delay="450">
                He created Digital Dogs that are driven by powerful AI technologies and nurtured by voice, gestures, emotions, and a digital environment. You can train your Digital Dog to do tricks and perform in competitions, care for them and breed them.
              </p>
              <p className="t-18-25" data-aos="fade-up" data-aos-delay="450">
                All Digital Dogs are secured as NFTs on the Ethereum blockchain and can be shared with other users. Thanks to our unique blockchain implementation, Digital Dogs can have multiple parents. Just like in real life. Your Digital Dog can be a family pet or an office dog, you decide.
              </p>
            </div>
            <div className="right-block">
              {/* <img src={frame} alt=""/> */}
              <video data-aos="fade-in" data-aos-delay="550" preload="none" className="small-bg" autoPlay={true} muted={true} playsInline={true} loop={true}>
                <source src={long_vid} type='video/mp4' />
              </video>
            </div>

          </div>
        </div>

        <div className="section home-sec3" id="demo">
          <img className="m-hide" src={short_blue} style={{
            position: "absolute",
            top: "-740px",
            transform: "rotate(-6deg)",
            zIndex: -1,
          }} />
          <div className="bg-wrap">


            <div className="container mobile-c flex-r v-btm" style={{ position: 'relative', zIndex: 2 }}>
              <div className="left-block">
                <img data-aos="fade-in" data-aos-delay="750" src={home_sec3_img} alt="" />
              </div>
              <div className="right-block">
                <h2 className="t-700 t-40-52 no-margin" data-aos="fade-up" data-aos-delay="50">
                  Play with Laika,
                  <br />the first Digital Dog
                </h2>
                <div data-aos="fade-up" data-aos-delay="250">
                  <p className="t-18-25 white-07">
                    The scientist dreamed about thousands of digital dogs running through the metaverse, marking their territory everywhere and making everyone happy… But it all began with his own true companion, Laika.
                  </p>
                  <p className="t-18-25 white-07">
                    Named after the first dog in space, she too is a pioneer for her AI four-legged family. Using voice command-AI and our PetOS engine, she’ll be thrilled to meet you!
                  </p>
                </div>

                <a
                  className="purple-btn cursor flex-r v-center hover-btn t-20-28 t-700"
                  data-aos="fade-up"
                  data-aos-delay="450"
                  href={demoLink}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center"
                  }}
                  target="_blank"
                >
                  Play with Laika
                  <img
                    src={right_btn_arr}
                    alt=""
                  />
                </a>

              </div>

            </div>
          </div>
          <img src={short_purple} className="m-hide" style={{
            position: "absolute",
            bottom: "-803px",
            transform: "rotate(-6deg)",
            zIndex: 1,
            left: "-900px",
          }} />

        </div>


        <div className="section home-sec3-1" id="the-launch" style={{
          padding: '100px 0px',
          zIndex: '3'
        }}>
          <img className="m-hide" src={long_purple} alt="" style={{
            position: 'absolute',
            bottom: '-185px',
          }} />
          <div className="container flex-c h-center" >
            <h1 data-aos="fade-in" data-aos-delay="50" className="t-700 t-40-52 t-center" style={{
              margin: 'unset',
              marginBottom: '104px'
            }}>
              The Launch
            </h1>



            <div className="home-sec3-1-itm flex-r v-center active">
              <img src={launch_img1} alt="" />

              <div className="flex-c">
                <span className="t-700 t-24-33">
                  DNA Vials Drop
                </span>
                <p className="t-14-19">
                  The scientist created 10K vials containing the necessary DNA sequence for generating incubated dog embryos. He is now looking for worthy humans that share his vision to begin the journey.
                </p>
                <HashLink className="t-18-24 t-700 flex-r v-center" smooth to="/#love" style={{
                  color: 'rgba(137, 44, 220, 1)',
                  position: 'relative',
                  zIndex: 5
                }}>
                  Learn more <img src={arr_purple_right} alt="" />
                </HashLink>
              </div>
              <div className="home-sec3-1-itm-line">
                <div className="dotLine flex-r v-center h-center">
                  <img src={launch_line_active} alt="" />
                </div>
                <img src={launch_line} alt="" />
                <img src={line_stright} className="line" alt="" />
              </div>
            </div>

            <div className="home-sec3-1-itm flex-r v-center right launch-2">
              <img src={launch_img2} alt="" />
              <div className="flex-c">
                <span className="t-700 t-24-33">
                  Gen 0 Embryos
                </span>
                <p className="t-14-19">
                  Incubate your vial and adopt one of 10K embryos, containing the first 3 community voted dog breeds.
                </p>
                <HashLink className="t-18-24 t-700 flex-r v-center" smooth to="/#love" style={{
                  color: 'rgba(137, 44, 220, 1)',
                  position: 'relative',
                  zIndex: 5
                }}>
                  Learn more <img src={arr_purple_right} alt="" />
                </HashLink>
              </div>
              <div className="home-sec3-1-itm-line reverse">
                <div className="dotLine flex-r v-center h-center">
                  <img src={launch_line_active} alt="" />
                </div>
                <img src={launch_line} alt="" />
                <img src={line_stright} className="line" alt="" />
              </div>
            </div>

            <div className="home-sec3-1-itm flex-r v-center launch-3">
              <img src={launch_img3} alt="" />
              <div className="flex-c">
                <span className="t-700 t-24-33">
                  $TREAT
                </span>
                <p className="t-14-19">
                  Launch of our $TREAT token. Access to your own private lab, a unique 3D virtual space. Start building an early connection with your Digital Dog embryo while it incubates!
                </p>
                <HashLink className="t-18-24 t-700 flex-r v-center" smooth to="/#tddc" style={{
                  color: 'rgba(137, 44, 220, 1)',
                  position: 'relative',
                  zIndex: 5
                }}>
                  Learn more <img src={arr_purple_right} alt="" />
                </HashLink>
              </div>
              <div className="home-sec3-1-itm-line">
                <div className="dotLine flex-r v-center h-center">
                  <img src={launch_line_active} alt="" />
                </div>
                <img src={launch_line} alt="" />
                <img src={line_stright} className="line" alt="" />
              </div>
            </div>

            <div className="home-sec3-1-itm flex-r v-center right launch-4">
              <img src={launch_img4} alt="" />
              <div className="flex-c">
                <span className="t-700 t-24-33">
                  Who Let The Dogs Out
                </span>
                <p className="t-14-19">
                  The incubation phase is done and your dog is created, it’s time to bond, train and take your new bestie for a walk!
                </p>
                <HashLink className="t-18-24 t-700 flex-r v-center" smooth to="/#road" style={{
                  color: 'rgba(137, 44, 220, 1)',
                  position: 'relative',
                  zIndex: 5
                }}>
                  Learn more <img src={arr_purple_right} alt="" />
                </HashLink>
              </div>
            </div>


            {/* <a
              data-aos="fade-up"
              data-aos-delay="550"
              className="home-sec3-1-btn cursor t-20-28 t-700 flex-r v-center hover-btn"
              data-aos="fade-up"
              data-aos-delay="450"
              href={discordLink}
              target="_blank"
            >
              Ready to Join?
              <img src={right_btn_arr} alt="" />
            </a> */}



          </div>
        </div>

        {/* <div className="section home-sec3-2" id="alpha" style={{
          paddingBottom: '167px',
          position: 'relative',
          zIndex: 4,
          backgroundColor: '#191A23'
        }}>
          <div className="container flex-r v-center">
            <div className="left" data-aos="fade-in" data-aos-delay="650" style={{ flex: 1 }}>
              <Slider className="slider-alpha" ref={slider2 => (this.slider2 = slider2)} {...settings2}>
                <img src={alpha_img} alt="" style={{
                  width: '100%',
                }} />
                <img src={alpha_img2} alt="" style={{
                  width: '100%',
                }} />
                <img src={alpha_img3} alt="" style={{
                  width: '100%',
                }} />
                <img src={alpha_img4} alt="" style={{
                  width: '100%',
                }} />
                <img src={alpha_img5} alt="" style={{
                  width: '100%',
                }} />
              </Slider>

              <div className="indicator-slider flex-r h-center v-center">
                <div onClick={(s) => this.alphaSwitch(s)} className="indicator-1 active cursor" id="indicator-1"></div>
                <div onClick={(s) => this.alphaSwitch(s)} className="indicator-2 cursor" id="indicator-2"></div>
                <div onClick={(s) => this.alphaSwitch(s)} className="indicator-3 cursor" id="indicator-3"></div>
                <div onClick={(s) => this.alphaSwitch(s)} className="indicator-4 cursor" id="indicator-4"></div>
                <div onClick={(s) => this.alphaSwitch(s)} className="indicator-5 cursor" id="indicator-5"></div>
              </div>
            </div>

            <div className="right" style={{

            }}>
              <h1 data-aos="fade-in" data-aos-delay="250" className="t-700 t-40-52" style={{
                margin: 'unset',
                marginBottom: '24px'
              }}>
                Join The Alpha Club
              </h1>
              <div data-aos="fade-up" data-aos-delay="350">
                <p className="t-700 t-20-28" style={{
                  opacity: '0.7',
                  marginBottom: '32px'
                }}>
                  The Digital Dogs Alpha Club is an exclusive collection of 3333 genesis NFTs, directly resulting from our community-first approach.
                </p>
                <p className="t-18-25" style={{
                  opacity: '0.7',
                  marginBottom: '1rem'
                }}>
                  Each hand-drawn Alpha Club ERC-721 NFT offers premium benefits in our ecosystem, such as token yielding, voting powers,  free first-generation embryo adoption, and first rights to adopt puppies from future breed launches.
                </p>
                <p className="t-18-25" style={{
                  opacity: '0.7',
                  marginBottom: '16px'
                }}>
                  The Alpha Club collection is based on over 150 traits and 15 digital dog heads inspired by real dog breeds and legendary wolves. All the traits and attributes will be generated randomly and revealed after the sale.
                </p>
                <p className="t-14-19" style={{
                  opacity: '0.5',
                }}>
                  Note: 2% of The Alpha Club NFTs will be withheld from the sale. We will use them for giveaways, puzzle rewards, and ensuring the creators of TDDC get their Alpha Club membership.
                </p>
              </div>

              <div className="flex-r">
                <a
                  // className="home-sec3-2-btn cursor t-20-28 t-700 flex-r v-center hover-btn"
                  className="home-sec3-2-btn cursor t-20-28 t-700 hover-btn"
                  data-aos="fade-up"
                  data-aos-delay="550"
                  href={discordLink}
                  target="_blank"
                >
                  Get a Whitelist Spot
                  <img src={right_btn_arr} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div> */}


        <div className="section home-sec3-3 flex-c h-center" id="love" style={{
          paddingBottom: '128px',
          paddingTop: '207px',
          backgroundColor: '#191B27',
          position: 'relative',
          zIndex: 3
        }}>
          <img className="m-hide" src={short_blue} alt="" style={{
            position: 'absolute',
            bottom: '-870px',
            transform: 'rotate(-7deg)',
            right: '-33%',
          }} />

          <div className="container flex-r v-center mobile-c">
            <div className="left" style={{
              maxWidth: '532px',
              marginRight: '32px'
            }}>
              <h1 data-aos="fade-up" data-aos-delay="150" className="t-40-52 t-700" style={{
                margin: 'unset',
                marginBottom: '24px',
              }}>
                It’s Time To Fall In Love
              </h1>
              <div data-aos="fade-up" data-aos-delay="250">
                <p className="t-20-28 t-700" style={{
                  opacity: '0.7',
                  marginBottom: '32px'
                }}>
                  Soon you will be able to adopt your metaverse companion! Every dog is unique, and its development depends on how you interact with them. Give your dog a name, earn their trust, train and compete with other owners, socialize and even breed your own NFT litter.
                </p>

                <p className="t-18-25" style={{
                  opacity: '0.7',
                  marginBottom: '1rem'
                }}>
                  The first generation will include three different breeds. If you miss your opportunity to adopt your favorite breed from the Lab, you will need to wait for the next breeding season, or check the secondary markets, which is why being an early adopter is important.
                </p>
                <p className="t-18-25" style={{
                  opacity: '0.7',
                }}>
                  Each dog has individual physical and psychological characteristics. Physical characteristics include colors, markings, coat, cyber elements, and psychological characteristics that impact their personality traits, such as their levels of intelligence, playfulness, curiosity, friendliness, adaptability, affection, and energy.
                </p>
              </div>
              {/* <button
                // className="cursor flex-r v-center hover-btn"
                className="cursor flex-r v-center"
                data-aos="fade-up"
                data-aos-delay="450"
                disabled
              > */}
              {/* <span className="t-20-28 t-700">
                    Play with Laika
                  </span> */}
              {/* <span
                  className="t-20-28 t-700"
                  style={{
                    color: "rgba(128, 128, 128, 0.5)"
                  }}
                >
                  Available soon
                </span> */}
              {/* <img
                    src={right_btn_arr}
                    alt=""
                  /> */}
              {/* </button> */}
            </div>
            <div className="right" data-aos="fade-in" data-aos-delay="350" style={{ flex: 1 }}>
              <img src={fallinlove_img} alt="" style={{
                width: '100%'
              }} />
            </div>
          </div>
        </div>


        <div className="section home-sec4" id="lab">

          <video data-aos="fade-in" data-aos-delay="850" className="" preload="none" autoPlay={true} muted={true} playsInline={true} loop={true} style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: 'cover',
            filter: "brightness(0.2)"
          }}>
            <source src={lab} type='video/mp4' />
          </video>
          <div className="container flex-r v-center mobile-c">

            <div className="left-block">
              <h2 data-aos="fade-up" data-aos-delay="50" className="t-40-52 t-700 no-margin">
                The Digital Dog Lab
              </h2>
              <div data-aos="fade-up" data-aos-delay="250">
                <p className="t-20-28 t-700 white-07">
                  Not a labrador; it’s where first-generation Digital Dogs are born.
                </p>
                <p className="t-18-25 white-07">
                  Unlike Laika, your future companion still needs some more time to develop. As a future Digital Dog owner, you will have access to your own private lab, a unique three-dimensional virtual space, where you can watch your digital pup grow, participate in community events, hang and display your favorite NFTs, and more. Start building an early connection with your Digital Dog while it incubates!
                </p>
                <p className="t-18-25 white-07">
                  The Headquarter Lab is open right now and ready for our first community vote for the initial three dog breeds to be created. Anyone can visit the Headquarter Lab and start exploring.
                </p>
              </div>
              <button
                // className="cursor t-20-28 t-700 flex-r v-center hover-btn"
                className="cursor t-20-28 t-700 flex-r v-center"
                data-aos="fade-up"
                data-aos-delay="450"
                disabled
              >
                <span
                  style={{
                    color: "rgba(128, 128, 128, 0.5)"
                  }}
                >
                  Explore the Lab (soon)
                </span>
                {/* <img src={right_btn_arr} alt="" /> */}
              </button>
            </div>
            {/* <div className="right-block flex-r h-right">
              <div className="home-dog-list flex-c h-center" data-aos="fade-in" data-aos-delay="650" style={{
                transition: 'all 300ms ease-out'
              }}>
                <h3 className="no-margin t-center t-24-33 t-700" style={{
                  marginBottom: '32px'
                }}>
                  Top 3 - 1st Release
                </h3>

                {this.state.loading ? <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> : ''}

                {this.state.retry ? <button className="t-18-25 cursor hover-btn" onClick={() => this.getDog()} style={{
                  backgroundColor: '#B92CDC',
                  color: 'white',
                  borderRadius: '12px',
                  padding: '18px 48px'
                }}>
                  Retry
                </button> : ''}


                <div className="flex-c" style={{
                  width: '100%'
                }}>
                  {this.state.dogs.map((d, i) =>
                    <div className={i == 3 ? "flex-r v-center with-line" : "flex-r v-center"} key={d.id} style={{
                      marginBottom: '16px'
                    }}>

                      {i < 3 ?
                        <img src={d.pictureUrl} alt="" style={{
                          width: '64px',
                          height: '64px',
                          marginRight: '24px',
                          objectFit: 'cover'
                        }} /> : ''}

                      <div className="t-20-28 t-700" style={{
                        flex: 1
                      }}>
                        {d.title}
                      </div>
                      <div className="t-16-22 t-right white-07">
                        {/* TODO Enable once proper integration with the API is done */}
            {/* {d.votes} votes *
                        0 votes
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div> */}
          </div>
        </div>

        <div className="section home-sec4-1 flex-c h-center" id="tddc" style={{
          position: 'relative',
          zIndex: 5,
          padding: '168px 0',
          backgroundColor: '#1A1B28'
        }}>
          <img className="m-hide" src={long_purple} alt="" style={{
            position: 'absolute',
            top: '-165px'
          }} />
          <img className="m-hide" src={short_blue} alt="" style={{
            position: 'absolute',
            bottom: '-871px',
            transform: 'rotate(-8deg)',
          }} />
          <div className="container flex-r v-center mobile-c">
            <div className="left" style={{

            }}>
              <h1 data-aos="fade-up" data-aos-delay="150" className="t-40-52 t-700" style={{
                margin: 'unset',
                marginBottom: '24px',
              }}>
                The Ecosystem
              </h1>
              <div data-aos="fade-up" data-aos-delay="250">
                <p className="t-20-28 t-700" style={{
                  opacity: '0.7',
                  marginBottom: '32px'
                }}>
                  At the center of the Digital Dog ecosystem is the Treat Token ($TREAT), an ERC-20 token. Our vision includes cross-d/app integrations, but that won’t stop us from introducing our very own metaverse with dog parks, shops and more…
                </p>
                <p className="t-18-25" style={{
                  marginBottom: '1rem',
                  opacity: '0.7'
                }}>
                  With $TREAT you’ll be able to develop your Digital Dog embryo in the lab, give it a name, customize your lab, and more. When your Digital Dog finally emerges from the incubator, $TREAT will be the main positive reinforcement mechanism to train your dog.
                </p>
                <p className="t-18-25" style={{
                  opacity: '0.7'
                }}>
                  You can earn or win $TREAT by interacting with your dog and being a responsible and loving dog owner, entering competitions or breeding Digital Dogs and selling embryos to newcomers.
                </p>
              </div>
            </div>
            <div className="right" data-aos="fade-in" data-aos-delay="350" style={{
              flex: 1
            }}>
              <img src={tddc_img} alt="" style={{
                width: '100%'
              }} />
            </div>
          </div>
        </div>

        <div className="section home-sec4-2" style={{
          backgroundColor: '#191A23',
          paddingTop: '128px',
          paddingBottom: '185px',
          position: 'relative',
          zIndex: 6,
        }}>
          <div className="container flex-r v-center mobile-c">
            <div className="left" data-aos="fade-in" data-aos-delay="350" style={{
              flex: 1
            }}>
              <img src={kudos_img} alt="" style={{
                width: '100%'
              }} />
            </div>
            <div className="right" style={{

            }}>
              <h1 data-aos="fade-up" data-aos-delay="150" className="t-40-52 t-700" style={{
                marginBottom: '24px'
              }}>
                We Kudos The Real Heros
              </h1>
              <div data-aos="fade-up" data-aos-delay="250">
                <p className="t-18-25" style={{
                  opacity: '0.7',
                  marginBottom: '1rem'
                }}>
                  Service dogs were the true inspiration for The Digital Pets Company. From their training program to the important work they do. That’s why we choose to donate 1% of the vials sale to GDA - Guide Dogs Of America.
                </p>
                <p className="t-18-25" style={{
                  opacity: '0.7',
                  marginBottom: '1rem'
                }}>
                  The funds will go to GDA and help facilitate the very important cause they work for and fund the training.
                </p>
                <p className="t-18-25" style={{
                  opacity: '0.7',
                }}>
                  Find more info here:
                  <br />
                  <a href="https://www.guidedogsofamerica.org/sponsorship/"> https://www.guidedogsofamerica.org/sponsorship/ </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="section home-sec8 flex-c h-center" style={{
          position: 'relative',
          zIndex: 5,
          paddingTop: '168px',
          backgroundColor: '#191A23'
        }}>
          <img className="m-hide" src={long_purple} alt="" style={{
            position: 'absolute',
            top: '-165px'
          }} />
          <div className="container flex-c h-center">
            <h2 className="t-700 t-40-52 t-center no-margin" data-aos="fade-up" data-aos-delay="50" style={{
              marginBottom: '48px'
            }}>
              Why Join Now
            </h2>

            <div>
              {/* </div><div className="dogonomic-list">
              <div className="left flex-c" data-aos="fade-up" data-aos-delay="450">
                <div className="t-24-33 t-700">
                  The Alpha Club Benefits:
                </div>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> Only Alpha Club members can yield $TREAT tokens. Owners with rarer NFTs benefit from a higher yield rate.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> You get voting rights, allowing you to influence ecosystem development.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> Get a free dog from the first release.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> ‘White list’ for all first-generation
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> Receive a unique NFT profile picture.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> And much more…
                </span>
              </div>
              <div className="right flex-c" data-aos="fade-up" data-aos-delay="650">
                <div className="t-24-33 t-700">
                  First Gen Dogs Benefits:
                </div>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> The community will vote for the breeds created in the Headquarter Lab for each drop.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> The first generations of Digital Dogs are immortal.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> Only first generation will have cyborg components.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> First-generation dogs will be the most fertile and have the lowest recovery time between litters.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> Only first-generation Digital Dogs will be able to breed new bloodlines and create new breeds.
                </span>
                <span className="t-18-25 white-07 flex-r v-top">
                  <img src={join_diamond} alt="" /> Priority access to all first-generation   Digital Dog releases from the Headquarters Lab.
                </span>
              </div>
            </div>
          </div>
        </div> */}


        <div className="section home-roadmap" id="road" style={{
          backgroundColor: '#191A23',
          paddingBottom: '41px',
          zIndex: 6
        }}>
          <div className="roadmap-line"></div>
          <div className="container">
            <div className="top flex-r just-space v-center" style={{
              marginBottom: '52px'
            }}>
              <h1 data-aos="fade-up" data-aos-delay="150" className="t-700 t-40-52" style={{
                margin: 'unset'
              }}>
                Pawmap
              </h1>

              <div className="roadmap-arrow">
                <img src={arr_right} alt="" onClick={() => this.goSlide(false)} className="cursor" />
                <img src={arr_right} alt="" onClick={() => this.goSlide(true)} className="cursor" />
              </div>
            </div>

            <Slider className="slider-roadmap" style={{
            }} ref={slider3 => (this.slider3 = slider3)} {...settings3}>

              <div className="slider-roadmap-itm">
                {/* <img src={poin_roadmap} alt=""/> */}
                <div className="slider-roadmap-itm-dot active">
                  <img src={launch_line_active} alt="" />
                </div>

                <div className="slider-roadmap-wrap flex-c">
                  <h2 className="t-24-33 t-700">
                    Q3 21'
                  </h2>
                  <span className="t-18-25">
                    &bull; Stealth launch, ideation and POC
                  </span>
                </div>
              </div>

              <div className="slider-roadmap-itm">
                <div className="slider-roadmap-itm-dot active">
                  <img src={launch_line_active} alt="" />
                </div>

                <div className="slider-roadmap-wrap flex-c">
                  <h2 className="t-24-33 t-700">
                    Q1/Q2 22'
                  </h2>
                  <span className="t-18-25">
                    &bull; Hello World
                  </span>
                  <span className="t-18-25">
                    &bull; Community
                  </span>
                  <span className="t-18-25">
                    &bull; Laika the first digital dog
                  </span>
                </div>
              </div>

              <div className="slider-roadmap-itm">
                <div className="slider-roadmap-itm-dot active current">
                  <img src={launch_line_active} alt="" />
                </div>

                <div className="slider-roadmap-wrap flex-c">
                  <h2 className="t-24-33 t-700">
                    Q3 22'
                  </h2>
                  <span className="t-18-25">
                    &bull; 10K DNA vials drop
                  </span>
                  <span className="t-18-25">
                    &bull; Incubation
                  </span>
                  <span className="t-18-25">
                    &bull; Traits reveal
                  </span>
                  <span className="t-18-25">
                    &bull; Land Sale
                  </span>
                </div>
              </div>

              <div className="slider-roadmap-itm">
                <div className="slider-roadmap-itm-dot">
                  <img src={launch_line_active} alt="" />
                </div>

                <div className="slider-roadmap-wrap flex-c">
                  <h2 className="t-24-33 t-700">
                    Q4 22'
                  </h2>
                  <span className="t-18-25">
                    &bull; $TREAT
                  </span>
                  <span className="t-18-25">
                    &bull; Access to personal labs
                  </span>
                  <span className="t-18-25">
                    &bull; Bond with your pup
                  </span>
                  <span className="t-18-25">
                    &bull; New breeds drop
                  </span>
                </div>
              </div>

              <div className="slider-roadmap-itm">
                <div className="slider-roadmap-itm-dot">
                  <img src={launch_line_active} alt="" />
                </div>

                <div className="slider-roadmap-wrap flex-c">
                  <h2 className="t-24-33 t-700">
                    2023 &amp; Beyond
                  </h2>
                  <span className="t-18-25">
                    &bull; Train your doggo
                  </span>
                  <span className="t-18-25">
                    &bull; Incubate new breeds
                  </span>
                  <span className="t-18-25">
                    &bull; Breeding
                  </span>
                  <span className="t-18-25">
                    &bull; Dog Parks
                  </span>
                  <span className="t-18-25">
                    &bull; Partnerships
                  </span>
                  <span className="t-18-25">
                    &bull; SDK
                  </span>
                </div>
              </div>
            </Slider>

            <div data-aos="fade-up" data-aos-delay="350" className="t-center t-14-19" style={{
              opacity: '0.5',
              marginTop: '88px'
            }}>
              *All dates are estimates and are subject to change
            </div>
          </div>
        </div>

        <div className="section home-after-roadmap" id="invest" style={{
          paddingTop: '128px',
          paddingBottom: '70px',
          zIndex: 5,
          backgroundColor: 'rgb(25, 26, 35)',
        }}>
          <div className="container flex-r just-space v-center mobile-c">
            <h1
              data-aos="fade-in"
              data-aos-delay="150"
              className="t-700 t-48-57"
              style={{
                margin: 'unset'
              }}
            >
              Our
              <br />Investors &amp;
              <br />Partners
            </h1>
            <div
              className="investors-container"
              style={{
                flex: "1"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Precede Technologies"
                  data-aos="fade-in"
                  data-aos-delay="250"
                  src={precedeTechnologiesLogo}
                  style={{
                    maxWidth: '300px',
                    maxHeight: '100px'
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Outlier Ventures"
                  data-aos="fade-in"
                  data-aos-delay="300"
                  src={outlierVenturesLogo}
                  style={{
                    maxWidth: '300px',
                    maxHeight: '100px'
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Max Stealth"
                  data-aos="fade-in"
                  data-aos-delay="350"
                  src={maxStealthLogo}
                  style={{
                    maxWidth: '300px',
                    maxHeight: '100px'
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Hawk Digital Innovation"
                  data-aos="fade-in"
                  data-aos-delay="400"
                  src={hawkDigitalInnovationLogo}
                  style={{
                    maxWidth: '300px',
                    maxHeight: '100px'
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Flori Ventures"
                  data-aos="fade-in"
                  data-aos-delay="450"
                  src={floriLogo}
                  style={{
                    maxWidth: '300px',
                    maxHeight: '100px'
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section home-sec10 flex-c h-center" id="team" style={{
          paddingBottom: '168px',
          paddingTop: '216px'
        }}>
          <img className="m-hide" src={long_purple} alt="" style={{
            position: 'absolute',
            top: '-165px'
          }} />
          <div className="container">

            <div className="top-block mobile-c flex-r just-space v-center">
              <h2 className="t-40-52 t-700 no-margin" data-aos="fade-in" data-aos-delay="50">
                The Team
              </h2>
              <p className="t-18-25 white-07" data-aos="fade-in" data-aos-delay="250">
                The Digital Pets Company was founded by dog-loving engineers and developers. The team consists of artificial intelligence, machine learning, and virtual reality experts who are keen to see how we can take mans’ best friend with us to the metaverse.
              </p>
            </div>

            <div className="btm-block team-container" style={{

            }}>

              {this.team.map((item, index) => this.teamComponent(item, index))}

            </div>

          </div>
        </div>


        <div className="section home-faq" id="faq">
          <div className="container">
            <h2 data-aos="fade-up" data-aos-delay="150" className="t-left t-700 t-40-52" style={{
              margin: 'unset',
              marginBottom: '48px'
            }}>
              FAQs
            </h2>

            <div className="faq-wrap" data-aos="fade-in" data-aos-delay="250">

              <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    What are AI Digital Dogs ?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  AI Digital Dogs are Persistent-state virtual objects laid on an interactive environment and secured as NFTs. Digital Dogs are nurtured by voice, sight, emotions, and a digital environment. Digital dogs were created to address social and emotional needs in the new digital reality and to be man's best friend across the metaverse.
                </p>
              </div>
              <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    Can I try now to play with Digital Dog?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  Yes, at the top of the page you can find a link to our proof of concept, ‘Laika’, the first digital dog.
                  We’ve invested a lot of time and effort in creating and training her, and we’re all very proud of her. Keep in mind, she is an early version, and there is a lot of work being done to make her more intelligent and autonomous.
                </p>
              </div>
              {/* <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    What is the total The Alpha Club NFT supply?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  There will be ever only 3333 club members
                  The next drops will be 10,000 Gen Alpha Digital Dogs embryos, 3 most demanded breeds are sold for the first drop.
                  As a club member ,you and the other members will be able to choose which breeds of dogs (or wolves) will be created, you’ll get the first drop for free and be whitelisted to all the rest of Gen Alpha drops.
                </p>
              </div> */}
              <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    What are the secondary royalty fees for NFT holders?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  TDD retains 6% royalties on secondary market sales to develop the AI Digital Dogs, the game and the bridge to other virtual worlds and social spaces across the metaverse.
                </p>
              </div>
              {/* <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    How many Digital Dogs will there be in total?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  We don't know. Gen Alpha are the only ones that are created rather than born.
                  Gen Alpha will be able to breed the next generations and so on. We’re aiming to make the digital dogs accessible to millions of users.
                </p>
              </div> */}
              <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    How will I be able to take my Digital Dog across the Metaverse?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  Our flagship development is a proprietary cross-apps SDK , a bridge that will allow Digital Dogs to accompany owners through different apps, platforms and games with the ability to communicate with the owner, other avatars, other digital dogs and with unknown digital environments.
                  Think about joining a VR remote office, virtual events, or your favorite game. Now think about it again with a cute Digital Dog by your side. Will you be there with a Gen Alpha Digital Dog when we reach this milestone?
                </p>
              </div>
              <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    How Digital dogs develop from embryos?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  You can do more than watch your pup grow in their incubator from the Lab. The fun starts right away. The Lab is a virtual 3D space where soon-to-be owners can participate in community events and follow the development of their Digital Dogs, from an embryo to a super cute puppy. You can take an active role in the development process, take care of the capsule, and affect the quality of your future dog. Did you know Digital Dog embryos enjoy classical music?
                </p>
              </div>
              <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    How can I train and raise my Digital Dog?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  At first, You will be able to meet your Digital Dog on your device in 3D with full voice-command interactions and optional face/gestures recognition.
                  You can configure shared access to family members, friends, colleagues and DAO members for joint owners of Digital Dogs.
                  Take good care of your Digital Dog and earn $TREAT to buy food, treats, and toys. Use them for positive reinforcement and reward your dog for good behavior.
                  Teaching your Digital Dog to sit or roll over is challenging, time-consuming, yet highly rewarding. Dogs that master cool tricks and skills will likely be rare and therefore more valuable.
                  Well-trained dogs will be in demand in the secondary market. Prospective owners view statistics to see how much time, effort, and money has gone into training a Digital Dog.
                </p>
              </div>
              <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    What is the Dog-park?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  The Park is a virtual shared space for our community and their Digital Dogs. More details soon!
                </p>
              </div>
              <div className="faq-item flex-c cursor" onClick={(s) => this.collapseFaq(s)}>
                <div className="faq-head flex-r v-center just-space">
                  <span className="t-700 t-20-28">
                    Where can I learn more about the Digital Dogs ecosystem and the $TREAT token?
                  </span>
                  <img src={caret_faq} alt="" />
                </div>
                <p className="t-18-25">
                  For more information about the ecosystem, the Digital Dogs NFTs, the native token of the Digital Dogs community ($TREAT) please read our whitepaper.
                  You can also check our socials below and approach our Team via Discord or Twitter with any questions you might have.
                </p>
              </div>



            </div>
          </div>
        </div>



        <div className="section home-sec11 flex-c h-center" id="join" style={{
          marginTop: "100px",
          backgroundColor: 'rgb(27,32,47)'
        }}>
          <img className="m-hide" src={long_blur_blue} alt="" style={{
            position: 'absolute',
            bottom: '-43px'
          }} />
          <div className="container flex-r mobile-c v-center">
            <div className="left">
              <h2 className="t-40-52 t-700 no-margin" data-aos="fade-up" data-aos-delay="50">
                Join Our Community
              </h2>
              <p className="t-18-25 white-07" data-aos="fade-up" data-aos-delay="250">
                The Digital Dogs Discord is live! Join us to get the news as soon as possible and follow our latest announcements to get in on our groundbreaking project.
              </p>

              <a
                data-aos="fade-up"
                data-aos-delay="450"
                className="hover-btn cursor"
                href={discordLink}
                target="_blank"
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <span
                    className="t-20-28 t-700"
                  >
                    Join Our Discord
                  </span>
                  <img
                    src={right_btn_arr} alt=""
                    style={{
                      marginLeft: '1rem'
                    }}
                  />
                </div>
              </a>

            </div>
            <div className="right">
              <video className="home-bottom-vid" muted preload="none" autoPlay={true} muted={true} playsInline={true} loop={true} style={{
                width: '100%'
              }}>
                <source src={shake} type='video/mp4' />
              </video>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </>
    )
  }
}
