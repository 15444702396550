import Homepage from './pages/Homepage';
import Privacy from './pages/Privacy';
import Tech from './pages/Tech';
import Terms from './pages/Terms';
import Vision from './pages/Vision';
import './App.css';
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import ReactGA from "react-ga";
import { useState } from 'react';

const history = createBrowserHistory();

// add google analytics for production builds
if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
  })
}

function App() {

  const [walletAddress, setWalletAddress] = useState("")

  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact >
          <Homepage walletAddress={walletAddress} setWalletAddress={setWalletAddress} />
        </Route>
        <Route path="/privacy" exact>
          <Privacy walletAddress={walletAddress} setWalletAddress={setWalletAddress} />
        </Route>
        <Route path="/technology" exact >
          <Tech walletAddress={walletAddress} setWalletAddress={setWalletAddress} />
        </Route>
        <Route path="/tos" exact>
          <Terms walletAddress={walletAddress} setWalletAddress={setWalletAddress} />
        </Route>
        <Route path="/vision" exact>
          <Vision walletAddress={walletAddress} setWalletAddress={setWalletAddress} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
