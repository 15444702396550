import { NavLink } from "react-router-dom";
import '../../css/footer.css';
import logo from "../../assets/logo.png";

import twitterIcon from "../../assets/social-ico/twitter-ico.png";
import linkedInIcon from "../../assets/social-ico/linkedin-ico.svg";
import discordIcon from "../../assets/social-ico/discord-ico.svg";
import instagramIcon from "../../assets/social-ico/instagram-ico.svg";
import youtubeIcon from "../../assets/social-ico/youtube-ico.webp";
import social5 from "../../assets/social-ico/last-ico.svg";
import email from "../../assets/email.svg";
import openSeaIcon from "../../assets/ico-last.png"
import mediumIcon from "../../assets/social-ico/medium-ico.png"
import tikTokIcon from "../../assets/social-ico/tik-tok-ico.png"

import long_blue from "../../assets/long-blur-blue.png"

import {
    careersLink,
    discordLink,
    instagramLink,
    linkedInLink,
    mediumLink,
    tikTokLink,
    twitterLink,
    youtubeLink
} from "../../Constants";


function Footer() {
    return (
        <div className="section footer">
            <img src={long_blue} alt="" style={{
                position: "absolute",
                zIndex: "-1",
                width: "100%",
                top: "-70px"
            }} />
            <div className="container">
                <div className="mobile-c top flex-r v-center just-space">
                    <img src={logo} className="hover-btn cursor" />
                    <div className="link-wrap flex-r v-center">
                        {/* <NavLink className="t-20-32 cursor hover-btn" to='/' exact>
                            Home
                        </NavLink> */}
                        <a
                            className="t-20-32 cursor hover-btn t-center"
                            href={careersLink}
                            target="_blank"
                        >
                            Careers
                        </a>
                        <NavLink
                            className="t-20-32 cursor hover-btn t-center"
                            to='/privacy'
                            exact
                        >
                            Privacy Policy
                        </NavLink>
                        <NavLink
                            className="t-20-32 cursor hover-btn t-center"
                            to='/tos'
                            exact
                        >
                            Terms of Service
                        </NavLink>
                    </div>
                </div>
                <div className="btm flex-r mobile-c v-btm just-space">
                    <div className="copy t-20-32 flex-c">
                        <a
                            className="t-18-25 flex-r v-center"
                            style={{
                                marginBottom: '45px'
                            }}
                            href="mailto:hello@thedigitaldogs.co"
                        >
                            <img src={email} alt="" style={{
                                marginRight: '15px'
                            }} />
                            hello@thedigitaldogs.co
                        </a>
                        <span>Copyright &copy; {new Date().getFullYear()} The Digital Pets Company&trade;, All Rights Reserved</span>
                    </div>
                    <div
                        className="social-wrap flex-r v-center"
                        style={{
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <a href={twitterLink} className="cursor hover-btn" target="_blank">
                            <img src={twitterIcon} alt="Twitter" />
                        </a>
                        <a href={linkedInLink} className="cursor hover-btn" target="_blank">
                            <img src={linkedInIcon} alt="LinkedIn" />
                        </a>
                        <a href={discordLink} className="cursor hover-btn" target="_blank">
                            <img src={discordIcon} alt="Discord" />
                        </a>
                        <a href={mediumLink} className="cursor hover-btn" target="_blank">
                            <img src={mediumIcon} alt="Medium" />
                        </a>
                        <a href={instagramLink} className="cursor hover-btn" target="_blank">
                            <img src={instagramIcon} alt="Instagram" />
                        </a>
                        <a href={youtubeLink} className="cursor hover-btn" target="_blank">
                            <img src={youtubeIcon} alt="YouTube" />
                        </a>
                        <a href={tikTokLink} className="cursor hover-btn" target="_blank">
                            <img src={tikTokIcon} alt="TikTok" />
                        </a>
                        {/* <a href="" className="cursor hover-btn ">
                            <img src={openSeaIcon} alt="OpenSea" />
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
