import { NavLink, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import '../../css/navbar.css';
import logo from "../../assets/pups-logo.png";
import logo2 from "../../assets/ico_logo.svg";

import $ from 'jquery';

import twitterIcon from "../../assets/social-ico/twitter-ico.png";
import linkedInIcon from "../../assets/social-ico/linkedin-ico.svg";
import discordIcon from "../../assets/social-ico/discord-ico.svg";
import instagramIcon from "../../assets/social-ico/instagram-ico.svg";
import youtubeIcon from "../../assets/social-ico/youtube-ico.webp";
import social5 from "../../assets/social-ico/last-ico.svg";
import last from "../../assets/ico-last.png"
import wallet from "../../assets/wallet.svg"
import caret_down from "../../assets/caret-down.svg";

import menu_ico from "../../assets/menu_ico.svg"
import close_ico from "../../assets/close_ico.svg"
import { discordLink, instagramLink, twitterLink, youtubeLink } from "../../Constants";
import { ethers } from "ethers";

function togglemenu() {
    $('.menu-overlay').toggleClass('active')
}

function collapseMenu(p) {
    $(p.currentTarget).toggleClass('active')
}

const scrollWidthOffset = (el, offset) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + (-offset), behavior: 'smooth' });
}

function Navbar(props) {

    const onConnectWalletClicked = async (event) => {
        event.preventDefault()

        if (props.walletAddress) return;

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        try {
            await provider.send("eth_requestAccounts")
            const address = await signer.getAddress()
            props.setWalletAddress(address)
        } catch (error) {
            console.error(error)
        }
    }

    $(document).ready(() => {
        var prevScrollpos = window.pageYOffset;

        if (document.querySelector('.launch-2')) {
            var sec3 = document.querySelector('.home-sec3-1'),
                launch2 = document.querySelector('.launch-2'),
                launch3 = document.querySelector('.launch-3'),
                launch4 = document.querySelector('.launch-4')
        }

        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;
            if (currentScrollPos > 100) {
                $('.navbar').css('background-color', 'rgba(0, 0, 0, 0.9)')
            } else {
                $('.navbar').css('background-color', 'transparent')
            }

            if (launch2) {
                if (currentScrollPos > sec3.offsetTop + (launch2.offsetTop - 300)) {
                    launch2.classList.add("active");
                } else {
                    launch2.classList.remove("active");
                }

                if (currentScrollPos > sec3.offsetTop + (launch3.offsetTop - 300)) {
                    launch3.classList.add("active");
                } else {
                    launch3.classList.remove("active");
                }

                if (currentScrollPos > sec3.offsetTop + (launch4.offsetTop - 300)) {
                    launch4.classList.add("active");
                } else {
                    launch4.classList.remove("active");
                }
            }

            prevScrollpos = currentScrollPos;
        }
    })

    return (
        <div className="section navbar">
            <div className="container flex-r v-center just-space">
                <Link to='/' className="logo-wrap cursor hover-btn flex-r v-center">
                    <img src={logo2} alt="" style={{
                        width: '47px',
                        height: '47px'
                    }} />
                </Link>
                <div className="m-hide nav-menu t-16-22 flex-r h-left">
                    <HashLink className="nav-link cursor active hover-btn" smooth to="/#demo" scroll={(el) => {
                        scrollWidthOffset(el, 80)
                    }}>
                        Demo
                    </HashLink>
                    <HashLink className="nav-link cursor active hover-btn" smooth to="/#the-launch" scroll={(el) => {
                        scrollWidthOffset(el, 80)
                    }}>
                        The Launch
                    </HashLink>
                    <NavLink className="nav-link cursor active hover-btn" to='/vision' exact>
                        Vision &amp; Purpose
                    </NavLink>
                    <NavLink className="nav-link cursor active hover-btn" to='/technology' exact>
                        Technology
                    </NavLink>
                    {/* <a className="nav-link cursor active hover-btn" href="https://gitbook.com" target="_blank">
                        Whitepaper <sub>(soon)</sub>
                    </a> */}
                    <div
                        style={{
                            color: 'gray',
                            marginRight: "32px"
                        }}
                    >
                        Whitepaper <sub style={{ color: 'gray' }}>(soon)</sub>
                    </div>
                    <button className="nav-link cursor hover-btn flex-r v-center dropdown t-16-22" onMouseEnter={(s) => collapseMenu(s)} onMouseLeave={(s) => collapseMenu(s)}>
                        More <img src={caret_down} alt="" />
                        <div className="flex-c h-left">
                            {/* <HashLink className="t-16-22" smooth to="/#alpha" scroll={(el) => {
                                scrollWidthOffset(el, 100)
                            }}>
                                Club
                            </HashLink> */}
                            <HashLink className="t-16-22" smooth to="/#lab" scroll={(el) => {
                                scrollWidthOffset(el, 100)
                            }}>
                                Lab
                            </HashLink>
                            <HashLink className="t-16-22" smooth to="/#road" scroll={(el) => {
                                scrollWidthOffset(el, 200)
                            }}>
                                Pawmap
                            </HashLink>
                            <HashLink className="t-16-22" smooth to="/#invest" scroll={(el) => {
                                scrollWidthOffset(el, 100)
                            }}>
                                Investors
                            </HashLink>
                            <HashLink className="t-16-22" smooth to="/#team" scroll={(el) => {
                                scrollWidthOffset(el, 100)
                            }}>
                                Team
                            </HashLink>
                            <HashLink className="t-16-22" smooth to="/#faq" scroll={(el) => {
                                scrollWidthOffset(el, 100)
                            }}>
                                FAQ
                            </HashLink>
                        </div>
                    </button>
                </div>
                <div className="m-show flex-r v-center h-right">

                    <img src={menu_ico} alt="" className="cursor hover-btn" onClick={() => togglemenu()} style={{
                        float: 'right'
                    }} />
                    <div className="menu-overlay">
                        <div className="flex-c h-center t-18-25" style={{
                            height: '100%',
                            paddingTop: '88px'
                        }}>
                            <Link to='/' className="logo-wrap cursor hover-btn flex-r v-center mobile">
                                <img src={logo2} alt="" style={{
                                    width: '47px',
                                    height: '47px'
                                }} />
                            </Link>
                            <img src={close_ico} alt="" className="nav-close-btn" onClick={() => togglemenu()} />


                            <HashLink className="nav-link cursor active hover-btn" smooth to="/#demo" scroll={(el) => {
                                $('.menu-overlay').removeClass('active')
                                scrollWidthOffset(el, 80)
                            }}>
                                Demo
                            </HashLink>
                            <HashLink className="nav-link cursor active hover-btn" smooth to="/#the-launch" scroll={(el) => {
                                $('.menu-overlay').removeClass('active')
                                scrollWidthOffset(el, 80)
                            }}>
                                The Launch
                            </HashLink>
                            <NavLink className="nav-link cursor active hover-btn" to='/vision' exact>
                                Vision &amp; Purpose
                            </NavLink>
                            <NavLink className="nav-link cursor active hover-btn" to='/technology' exact>
                                Technology
                            </NavLink>
                            {/* <a className="nav-link cursor active hover-btn" href="https://gitbook.com" target="_blank">
                                Whitepaper
                            </a> */}
                            <div
                                className="whitepaper"
                                style={{
                                    color: 'gray',
                                }}
                            >
                                Whitepaper <sub style={{ color: 'gray' }}>(soon)</sub>
                            </div>
                            <button className="nav-link cursor hover-btn flex-r v-center dropdown mobile flex-c h-left" onClick={(s) => collapseMenu(s)}>
                                <span className="t-16-22">
                                    More <img src={caret_down} alt="" />
                                </span>
                                <div className="flex-c h-left menu">
                                    {/* <HashLink className="t-16-22" smooth to="/#alpha" scroll={(el) => {
                                        $('.menu-overlay').removeClass('active')
                                        scrollWidthOffset(el, 100)
                                    }}>
                                        Club
                                    </HashLink> */}
                                    <HashLink className="t-16-22" smooth to="/#lab" scroll={(el) => {
                                        $('.menu-overlay').removeClass('active')
                                        scrollWidthOffset(el, 100)
                                    }}>
                                        Lab
                                    </HashLink>
                                    <HashLink className="t-16-22" smooth to="/#road" scroll={(el) => {
                                        $('.menu-overlay').removeClass('active')
                                        scrollWidthOffset(el, 200)
                                    }}>
                                        Pawmap
                                    </HashLink>
                                    <HashLink className="t-16-22" smooth to="/#invest" scroll={(el) => {
                                        $('.menu-overlay').removeClass('active')
                                        scrollWidthOffset(el, 100)
                                    }}>
                                        Investors
                                    </HashLink>
                                    <HashLink className="t-16-22" smooth to="/#team" scroll={(el) => {
                                        $('.menu-overlay').removeClass('active')
                                        scrollWidthOffset(el, 100)
                                    }}>
                                        Team
                                    </HashLink>
                                    <HashLink className="t-16-22" smooth to="/#faq" scroll={(el) => {
                                        $('.menu-overlay').removeClass('active')
                                        scrollWidthOffset(el, 100)
                                    }}>
                                        FAQ
                                    </HashLink>
                                </div>
                            </button>

                            <div className="mobile-social-wrap">
                                <a href={twitterLink} className="cursor hover-btn" target="_blank">
                                    <img src={twitterIcon} alt="Twitter" />
                                </a>
                                <a href={discordLink} className="cursor hover-btn" target="_blank">
                                    <img src={discordIcon} alt="Discord" />
                                </a>
                                <a href={instagramLink} className="cursor hover-btn" target="_blank">
                                    <img src={instagramIcon} alt="Instagram" />
                                </a>
                                <a href={youtubeLink} className="cursor hover-btn" target="_blank">
                                    <img src={youtubeIcon} alt="YouTube" />
                                </a>
                                {/* <a href="" className="cursor hover-btn">
                                    <img src={last} alt="" />
                                </a> */}
                            </div>

                            {
                                window.ethereum
                                    ?
                                    props.walletAddress
                                        ?
                                        <button
                                            className="cursor hover-btn connect-btn mobile t-18-25 flex-r v-center h-center"
                                        >
                                            {props.walletAddress.substring(0, 5)}...{props.walletAddress.substring(props.walletAddress.length - 4, props.walletAddress.length)}
                                        </button>
                                        :
                                        <button
                                            className="cursor hover-btn connect-btn mobile t-18-25 flex-r v-center h-center"
                                            onClick={onConnectWalletClicked}
                                        >
                                            Connect a wallet <img src={wallet} alt="" />
                                        </button>
                                    :

                                    <a
                                        className="cursor hover-btn connect-btn mobile t-18-25 flex-r v-center h-center"
                                        href="https://metamask.io"
                                        target="_blank"
                                    >
                                        Install MetaMask
                                    </a>
                            }

                        </div>
                    </div>
                </div>
                <div className="m-hide nav-social flex-r h-right v-center">
                    <a href={twitterLink} className="cursor hover-btn" target="_blank">
                        <img src={twitterIcon} alt="Twitter" />
                    </a>
                    <a href={discordLink} className="cursor hover-btn" target="_blank">
                        <img src={discordIcon} alt="Discord" />
                    </a>
                    <a href={instagramLink} className="cursor hover-btn" target="_blank">
                        <img src={instagramIcon} alt="Instagram" />
                    </a>
                    <a href={youtubeLink} className="cursor hover-btn" target="_blank">
                        <img src={youtubeIcon} alt="YouTube" />
                    </a>
                    {
                        window.ethereum
                            ?
                            props.walletAddress
                                ?
                                <button
                                    className="cursor hover-btn connect-btn t-18-25 flex-r v-center h-center"
                                >
                                    {props.walletAddress.substring(0, 5)}...{props.walletAddress.substring(props.walletAddress.length - 4, props.walletAddress.length)}
                                </button>
                                :
                                <button
                                    className="cursor hover-btn connect-btn t-18-25 flex-r v-center h-center"
                                    onClick={onConnectWalletClicked}
                                >
                                    Connect a wallet <img src={wallet} alt="" />
                                </button>
                            :
                            <a
                                className="cursor hover-btn connect-btn t-18-25 flex-r v-center h-center"
                                href="https://metamask.io"
                                target="_blank"
                            >
                                Install MetaMask
                            </a>
                    }
                </div>
            </div>
        </div>
    );
}

export default Navbar;
