import React, { Component } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import "../css/terms.css";

export default class Terms extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Navbar walletAddress={this.props.walletAddress} setWalletAddress={this.props.setWalletAddress} />
                <div
                    className="container"
                    style={{
                        paddingTop: "100px",
                        paddingBottom: "100px"
                    }}
                >
                    <h1>Terms of Service</h1>
                    <p>Last updated: February 2022</p>
                    <p>The Digital Pets Company Ltd. ("<b>Digital Pets</b>", "<b>us</b>", "<b>our</b>", or "<b>we</b>"), a company incorporated in the State of Israel with Company No. 516546173, owns and operates this website ("<b>Site</b>"), and the services provided thereon, including without limitation, any informational material if and as available from time to time, and participating in one of our interactive demos, as further detailed below ("<b>Demo</b>", and collectively "<b>Services</b>"). These Terms of Service ("<b>Terms</b>") govern your access and use of the Site and/or Services. Our Privacy Notice, available at https://thedigitaldogs.co/privacy ("<b>Privacy Notice</b>") governs our collection, processing and transfer of any Personal Data (as such term is defined in the Privacy Notice). "<b>You</b>" means an individual using the Site and/or Services.</p>
                    <p>Please read these Terms carefully. By visiting our Site and/or clicking "I Agree" (prior to using certain Services), as applicable, you agree to be bound by these Terms and our Privacy Notice. We may amend these Terms from time to time, and provide notice thereof on the Site. After such change, you will be required to assent to the amended Terms. If you do not agree to any of these Terms, please do not click the button marked "Agree" and do not use the Services.</p>
                    <ul>
                        <li>
                            <b>Use of Services</b>
                            <ul>
                                <li>Subject to these Terms, Digital Pets allows you to access and use the Services on a non-exclusive basis for informational and entertainment purposes.</li>
                                <li>As part of the Services, (i) the Demos will allow you to interact with our first digital AI dog, Laika, and/or access our lab Demo, and/or (ii) you may register for a randomly selected spot on our whitelist, to be eligible for future early purchase of one of our Digital Dogs, ownership of which is represented by a unique item known as a Non-Fungible Token, minted on blockchain, under certain terms and conditions to be published ("<b>Whitelist</b>"). Selection for the Whitelist will be done randomly. You represent and warrant that you will have no claim against us in connection with the Whitelist process.</li>
                                <li>Use of and access to the Services is void where prohibited by law. You represent and warrant that (a) your use of the Services does not violate any applicable law, regulation, or obligation you may have to a third party; and (b) you shall comply with applicable laws, regulations, guidelines, and these Terms throughout your use of the Services.</li>
                            </ul>
                        </li>
                        <li><b>User Content.</b> Through the Site and/or Services, you will also be able to submit certain content, such as your email address, text inquiries, votes and/or voice commands or video footage on the Demos ("<b>User Content</b>"). If you choose to provide User Content, you represent and warrant that (i) the User Content are yours and you have the right to provide such User Content; (ii) the provision of such User Content will not violate any law; and (iii) it will not violate any third-party rights.</li>
                        <li><b>Use Restrictions.</b> You may not do or attempt to do or facilitate a third party in doing any of the following: (1) decipher, decompile, disassemble, or reverse-engineer any of the software and/or code, if and as applicable, used to provide the Services without our prior written authorization, including framing or mirroring any part of the Services; (2) circumvent, disable, or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of any content; (3) use the Site, Services, or content thereon in connection with any commercial endeavors in any manner, except for the purposes specifically set forth in these Terms; (4) use any robot, spider, site search or retrieval application, or any other manual or automatic device or process to retrieve, index, data-mine, or in any way reproduce or circumvent the navigational structure or presentation of the Site or Services; (5) use the Site, Services, or content thereon in any manner not permitted by these Terms or applicable law.</li>
                        <li>
                            <b>Intellectual Property.</b>
                            <ul>
                                <li>Digital Pets or its licensors, as the case may be, have all right, title and interest in the Site, Services (including any Demo), and any content thereon, including its overall appearance, text, graphics, graphics design, videos, demos, interfaces, and underlying source files, and all worldwide intellectual property rights, the trademarks, service marks, and logos contained therein, whether registered or unregistered. Except as expressly permitted herein, you may not copy, further develop, reproduce, republish, modify, alter download, post, broadcast, transmit or otherwise use the content of the Services for any purpose. You will not remove, alter, or conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in the Services, if any. All trademarks are trademarks or registered trademarks of their respective owners. Nothing in these Terms should be construed as granting you any right to use any trademark, service mark, logo, or trade name of Digital Pets or any third party. If you provide Digital Pets with any feedback regarding any content on the Services, Digital Pets may use all such feedback without restriction and shall not be subject to any non-disclosure or non-use obligations in respect of such feedback.</li>
                                <li>You have all right, title and interest in the User Content you submit. By submitting any User Content, you grant Digital Pets and its successors and assignees a worldwide, non-exclusive, royalty-free, sub-licensable and transferable license under any of your intellectual property, moral or privacy rights to use, copy such User Content on, through or in connection with the Site, such as for the purpose of responding to your inquiries, contact you, or for marketing purposes.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Disclaimers and Disclaimer of Warranty</b>
                            <ul>
                                <li>All information and content posted on the Site is for informational purposes only and Company provides no guarantees with respect thereto. Your use of the Site and/or Services is at your sole discretion and risk. The Services and any content thereon are provided on an AS IS and AS AVAILABLE basis without warranties of any kind. We do not represent or warrant that Services will be of good quality or useful for your needs.</li>
                                <li>WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO THE SITE, SERVICES, OR ANY CONTENT THEREON, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, (I) REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THE SITE AND/OR SERVICES; (II) THAT THE SITE OR SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; (III) REGARDING THE ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY CONTENT OR INFORMATION PROVIDED.</li>
                                <li>No advice or information, whether oral or written, obtained by you from us, shall create any warranty that is not expressly stated in these Terms. If you choose to rely on such information, you do so solely at your own risk. Some states or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion of implied warranties.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Limitation of Liability</b>
                            <ul>
                                <li>Without derogating from any of the foregoing, we assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of any content or the Services. We are not responsible for any problems or technical malfunction or failure of any telephone network or lines, computer online systems or equipment, servers or providers, software, failure due to technical problems or traffic congestion on the Internet or on the Services. Under no circumstances shall we be responsible for any loss or damage, including personal injury or death and any injury or damage to any person's property, including mobile device or computer, resulting from the conduct of any users of the Services, whether online or offline. In addition, we assume no responsibility for any incorrect data, including Personal Data provided by you or on your behalf and you hereby represent and warrant that you are solely responsible for any and all data provided to Digital Pets, including any incorrect data and you shall assume any and all liability for any consequences of provision of such incorrect data to us.</li>
                                <li>IN NO EVENT SHALL DIGITAL PETS, OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, ASSIGNEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICES, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THE SERVICES, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT DIGITAL PETS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL OUR MAXIMUM CUMULATIVE LIABILITY TO YOU EXCEED US$50.</li>
                            </ul>
                        </li>
                        <li><b>Indemnification.</b> You agree to indemnify, defend, and hold harmless Digital Pets, its employees, directors, officers, subcontractors and agents, against any and all claims, damages, or costs, losses, liabilities or expenses (including reasonable court costs and attorneys’ fees) that arise directly or indirectly from: (a) breach of these Terms by you or anyone using your computer and/or mobile device; (b) any claim, loss or damage experienced from your use or attempted use of (or inability to use) the Site or Services; (c) your violation of any law or regulation relating to the Site or Services; (d) your infringement of any right of any third party.</li>
                        <li><b>Third-Party Content.</b> The Site may provide you with third-party links to websites, applications, and services. We make no promises regarding any content, goods or services provided by such third parties and all use of third-party websites and applications is at your own risk. Additionally, we do not accept responsibility for the privacy policies of such third parties. We do not endorse any products offered by third parties and we urge our users to exercise caution in using third-party websites or applications.</li>
                        <li><b>Miscellaneous.</b> These Terms shall be governed solely by the laws of the State of Israel, and without regard to the United Nations Convention on the International Sales of Goods and the competent courts in the State of Israel shall have exclusive jurisdiction to hear any disputes arising hereunder. In the event that any provision of these Terms is held to be unenforceable, such provision shall be replaced with an enforceable provision which most closely achieves the effect of the original provision, and the remaining terms of these Terms shall remain in full force and effect. Nothing in these Terms creates any agency, employment, joint venture, or partnership relationship between you and Company or enables you to act on behalf of Company. Except as may be expressly stated in these Terms, these Terms constitute the entire agreement between us and you pertaining to the subject matter hereof, and any and all other agreements existing between us and you relating thereto are hereby canceled. We may assign and/or transfer our rights and obligations hereunder to any third party without prior notice. You shall not assign and/or transfer any of your rights or obligations hereunder, and any assignment in violation of the foregoing shall be void. No waiver of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. If we are required to provide notice to you hereunder, we may provide such notice to the contact details you provided when contacting us.</li>
                    </ul>
                </div>
                <Footer />
            </div>
        )
    }

}
