export const discordLink = "https://discord.gg/thedigitaldogs"
export const linkedInLink = "https://www.linkedin.com/company/the-digital-dogs-company"
export const twitterLink = "https://twitter.com/thedigitaldogs"
export const openSeaLink = "TODO"
export const instagramLink = "https://instagram.com/thedigitaldogs"
export const careersLink = "https://the-digital-pets-company.breezy.hr"
export const youtubeLink = "https://www.youtube.com/channel/UCWc7wiRjDifZFw8_GBph0Pg"
export const mediumLink = "https://medium.com/@digitaldogs"
export const demoLink = "https://laika.thedigitaldogs.co"
export const tikTokLink = "https://www.tiktok.com/@thedigitaldogs"
