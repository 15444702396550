import React, { Component } from "react";
import $ from 'jquery';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import AOS from 'aos';
import 'aos/dist/aos.css';

import sec1_img from '../assets/feature-img1.png'
import sec3_img from "../assets/home-sec3-img.png"
import sec5_img from "../assets/dog-platform.png"

import join_img from "../assets/join-img.png"
import join_ico from "../assets/join-ico.svg"

import kid_dog from "../assets/kid-dog.png"

import platform_ico1 from "../assets/vr-ico.png"
import platform_ico2 from "../assets/mobile-ico.png"
import platform_ico3 from "../assets/web-ico.png"
import platform_ico4 from "../assets/pc-ico.png"
import platform_ico5 from "../assets/console-ico.png"

import long_purple from "../assets/long-purple-blur.png"
import short_purple from "../assets/short-blur-purple.png"
import short_blue from "../assets/short-blur-blue.png"
import blur_purple from "../assets/blur-purple.png"

import spark from "../assets/spark.png"

import before from "../assets/before.png"
import after from "../assets/after.png"



export default class Vision extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    compare() {
        var side = $('.slideTarget');
        var ori = $('.slideBase');
        var oriW = ori.width();
        var handle_slide = $('.sliderHandle');
        var dragging = false
        var of = document.querySelector('.slideBase').offsetLeft

        handle_slide.mousedown(function (e) {
            e.preventDefault();
            dragging = true;
            $(document).mousemove(function (ex) {
                if (ex.pageX > of) {
                    side.css("width", (oriW ? oriW : $('.slideBase').width()) - (ex.pageX - of));
                }
            });
        });

        $(document).mouseup(function (e) {
            if (dragging) {
                $(document).unbind('mousemove');
                dragging = false;
            }
        });


        document.querySelector('.sliderHandle').addEventListener("touchstart", (e) => {
            if (e.cancelable) {
                e.preventDefault();
                e.stopPropagation();
            }
            dragging = true;

            $('.sliderHandle').on('touchmove', function (ee) {
                var xPos = ee.originalEvent.touches[0].pageX;
                // console.log(xPos)
                if (xPos > of) {
                    side.css("width", (oriW ? oriW : $('.slideBase').width()) - (xPos - of));
                }
            });


            // document.querySelector('.sliderHandle').addEventListener("touchmove", (e2) => {
            //     console.log('move')
            //     console.log(e2.originalEvent)
            //     if(e.pageX > of){
            //         side.css("width", (oriW ? oriW : $('.slideBase').width()) - (e.pageX - of));
            //     }
            // });
        });
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(() => {
            this.compare()
            this.textInterval()
        })

        AOS.init({
            throttleDelay: 99,
            duration: 700,
            delay: 50,
            once: true,
            anchorPlacement: 'top-bottom',
            useClassNames: true,
        });

    }

    textInterval() {
        var t = 0
        var feature = [
            'Virtual event',
            'Social network',
            'Game',
            'VR/AR app',
            'Virtual world'
        ]

        function go() {
            setTimeout(() => {
                $('.mission-tab-btn').text(feature[t])
                t++
                if (t >= feature.length) {
                    t = 0
                }
                go()
            }, 1500)
        }

        go()
    }


    render() {
        require('../css/vision.css')
        return (
            <>
                <Navbar walletAddress={this.props.walletAddress} setWalletAddress={this.props.setWalletAddress}></Navbar>

                <div
                    className="section"
                    style={{
                        paddingTop: '200px',
                        paddingBottom: '150px',
                        position: 'relative'
                    }}
                >
                    <img className="m-hide" src={spark} alt="" style={{
                        position: 'absolute',
                        top: '-1300px',
                        left: '-1450px'
                    }} />
                    <div className="container flex-r just-space mobile-c v-center">
                        <div style={{
                            width: '100%',
                            maxWidth: '612px'
                        }}>
                            <h1 className="t-700 t-40-52 no-margin" style={{
                                margin: 'unset',
                                marginBottom: '24px'
                            }} data-aos="fade-up" data-aos-delay="50">
                                Enabling companionship in the metaverse
                            </h1>
                            <p className="t-18-25 white-07" style={{
                                width: '100%',
                                maxWidth: '516px'
                            }} data-aos="fade-up" data-aos-delay="250">
                                We are building the first-ever AI Digital Dog™ platform for users to create and interact with their unique Digital Dog. The platform will be extensible, allowing owners to take their dogs on journeys across the metaverse.
                            </p>
                        </div>
                        <div style={{
                            flex: 1,
                        }} data-aos="fade-in" data-aos-delay="450">
                            <img className="feature-sec1-img" src={sec1_img} alt="" />
                        </div>
                    </div>
                </div>

                <div className="section" style={{
                    marginBottom: '100px'
                }}>
                    <div className="container">
                        <div className="flex-r just-space v-center mobile-c" style={{
                            backgroundColor: '#10141C',
                            width: '100%',
                            padding: '56px 45px',
                        }}>
                            <div data-aos="fade-in" data-aos-delay="350" style={{
                                flex: 1,
                                width: '100%'
                            }}>
                                <div className="slideBase" style={{
                                    // background: `url(${before})`,
                                    // backgroundPosition: 'right',
                                    // backgroundSize: 'auto 100%',
                                    // backgroundRepeat: 'no-repeat',
                                }}>
                                    <img src={before} alt="" style={{
                                        width: '100%'
                                    }} />
                                    <div className="slideTarget" style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        width: '80%',
                                        height: '100%',
                                        background: `url(${after})`,
                                        backgroundPosition: 'right',
                                        backgroundSize: 'auto 100%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundColor: '#10141C'
                                    }}>
                                        <div className="sliderHandle" style={{
                                            height: '100%',
                                            width: '4px',
                                            backgroundColor: 'white',
                                            borderRadius: '4px',
                                            cursor: 'col-resize',
                                            left: '-4px'
                                        }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-c h-left digital-dog-block" >
                                <h2 className="t-40-52 t-700 no-margin" style={{
                                    marginBottom: '32px'
                                }} data-aos="fade-up" data-aos-delay="50">
                                    Digital Dogs are
                                    <br />
                                    not a game
                                </h2>
                                <div data-aos="fade-up" data-aos-delay="250">
                                    <p className="t-18-25 white-07">
                                        In traditional video games, you play a character role and follow a narrative written by the developers. Whereas in many VR apps, we play the role of ourselves using avatars, or a version of ourselves depending on whether it’s for professional, entertainment, or social reasons.
                                    </p>
                                    <p className="t-18-25 white-07">
                                        The world is evolving, and more virtual environments are emerging. VR is becoming an increasingly important part of our daily lives, offering us new ways to collaborate, play, work, shop, socialize and interact with others. The metaverse is coming, and it won’t be a single app; there will be thousands of them, and you can have your Digital Dog at your side.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <img className="m-hide" src={short_blue} style={{
                        position: "absolute",
                        top: "-740px",
                        transform: "rotate(-6deg)",
                        zIndex: -1,
                    }} />
                    <div className="shape" style={{
                        backgroundColor: 'rgb(28, 31, 42)',
                        clipPath: 'polygon(0 1%, 100% 13%, 100% 100%, 0 87%)',
                        paddingBottom: '131px',
                        paddingTop: '131px',
                        position: 'relative',
                        zIndex: '2',
                    }}>
                        <div className="container">
                            <div className="flex-r mobile-c" style={{
                                width: '100%'
                            }}>
                                <div className="wrapper-enjoy-sec flex-c h-left">
                                    <h2 className="t-40-52 t-700 no-margin" data-aos="fade-up" data-aos-delay="50">
                                        Enjoy the benefits
                                        <br />
                                        of a real dog
                                    </h2>
                                    <div data-aos="fade-up" data-aos-delay="250">
                                        <p className="t-18-25 white-07">
                                            Despite living in an area of hyperconnectivity, many of us feel increasingly isolated and lonely in our daily lives. Many of us find it hard to break the ice and meet new people, and others find social interactions provoke anxiety.
                                        </p>
                                        <p className="t-18-25 white-07">
                                            Dogs are mans’ best friend, and they make us less lonely, more social, and happier. Your Digital Dog is the perfect ice breaker in the metaverse. As the world becomes increasingly virtual, we need to answer an important question; can a virtual dog make us less lonely?
                                        </p>
                                    </div>
                                </div>
                                <div className="" style={{
                                    flex: 1
                                }}>
                                    <img src={kid_dog} alt="" style={{
                                        width: '100%',
                                        transform: 'scale(1.3)',
                                        position: 'relative',
                                        right: '-60px',
                                        zIndex: 1,
                                    }} data-aos="fade-in" data-aos-delay="450" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={short_purple} className="m-hide" style={{
                        position: "absolute",
                        bottom: "-803px",
                        transform: "rotate(-6deg)",
                        zIndex: 1,
                        left: "-900px",
                    }} />
                </div>


                <div className="section" style={{
                    paddingTop: '48px'
                }}>
                    <img src={long_purple} alt="" className="m-hide" style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: 0,
                        bottom: '-400px',
                    }} />
                    <div className="container flex-r mobile-c" style={{
                        backgroundColor: '#191A23',
                        paddingBottom: '100px'
                    }}>
                        <div className="left" style={{
                            flex: 1
                        }} data-aos="fade-in" data-aos-delay="650">
                            <img src={sec3_img} alt="" className="sec3-img" />
                        </div>
                        <div className="right flex-c" className="feature-platform-block" >
                            <h2 className="t-700 t-40-52 no-margin" style={{
                                marginBottom: '30px'
                            }} data-aos="fade-up" data-aos-delay="50">
                                Bring your Digital Dog to your favorite place to hang out online
                            </h2>
                            <div className="tab flex-r v-center h-center" style={{
                                flexWrap: 'wrap'
                            }} data-aos="fade-up" data-aos-delay="250">
                                <div className="t-18-25 mission-tab-btn active">
                                    Virtual event
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="450">
                                <p className="t-18-25 white-07" style={{
                                    marginTop: '40px'
                                }}>
                                    We combine AI and XR to create a continuous two-way emotional relationship with a virtual being across virtual worlds, apps, and games. Each dog is unique in its appearance and personality, powered by our PetOS and individual artificial minds.
                                </p>
                                <p className="t-18-25 white-07">
                                    Our mission is to create lifelike interactions using voice, visual, and touch communication in a way that allows you to interact with your Digital Dog. You can give them a name, train them, love them, and play with other people and their Digital Dogs when they meet, whether in VR worlds, AR, mobile, or web apps.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section" style={{
                    paddingTop: '157px',
                    paddingBottom: '89px'
                }}>
                    <div className="container flex-c h-center">
                        <h2 className="t-700 t-40-52 t-center no-margin" data-aos="fade-up" data-aos-delay="50">
                            Cross-application and multi-platform
                        </h2>
                        <p className="t-18-25 t-center white-07" style={{
                            width: '100%',
                            maxWidth: '792px',
                            marginTop: '32px'
                        }} data-aos="fade-up" data-aos-delay="250">
                            Our Digital Dogs will accompany their owners through popular VR apps and experiences across the metaverse.
                        </p>
                        <img src={blur_purple} className="m-hide" alt="" style={{
                            position: 'absolute',
                            bottom: '-150px',
                            zIndex: '-1'
                        }} data-aos="fade-in" data-aos-delay="850" />
                        <img src={sec5_img} alt="" style={{
                            width: '100%',
                            maxWidth: '850px',
                            marginTop: '63px',
                            marginBottom: '135px',
                        }} data-aos="fade-in" data-aos-delay="450" />
                        <div className="flex-r just-space mobile-c platform-img-wrap" data-aos="fade-in" data-aos-delay="650">
                            <img src={platform_ico1} alt="" />
                            <img src={platform_ico2} alt="" />
                            <img src={platform_ico3} alt="" />
                            <img src={platform_ico4} alt="" />
                            <img src={platform_ico5} alt="" />
                        </div>
                    </div>
                </div>


                <div className="section" style={{
                    paddingBottom: '96px'
                }}>
                    <div className="container flex-r mobile-c">
                        <div className="left" style={{
                            flex: '1'
                        }} data-aos="fade-in" data-aos-delay="850">
                            <img src={join_img} alt="" className="feature-join-img" />
                        </div>
                        <div className="join-program-right flex-c h-left">
                            <span className="t-20-28 white-07" data-aos="fade-up" data-aos-delay="50">
                                Developers:
                            </span>
                            <h2 className="t-40-52 t-700 no-margin" style={{
                                marginTop: '8px',
                                marginBottom: '32px'
                            }} data-aos="fade-up" data-aos-delay="250">
                                Is your VR app pet friendly ?
                            </h2>
                            <div data-aos="fade-up" data-aos-delay="450">
                                <p className="t-18-25 white-07">
                                    Developers, is your VR app pet-friendly?
                                    Our team has decades of collective experience working with artificial intelligence, machine learning, robotics, game development, graphic design, blockchain infrastructure, and of course - dogs!
                                </p>
                                <p className="t-18-25 white-07">
                                    We believe that Digital Dogs are for everyone, and we are passionate about matching them with millions of existing XR users. Using our SDK, you can allow users to bring their Digital Dogs to your app.
                                </p>
                            </div>
                            <a
                                href="https://forms.gle/458fBJQ8ULdhjWdw9"
                                className="flex-r v-center t-20-28 t-700 hover-btn cursor"
                                style={{
                                    backgroundColor: 'rgba(185, 44, 220, 1)',
                                    padding: '20px 48px',
                                    borderRadius: '12px',
                                    marginTop: '48px',
                                }}
                                data-aos="fade-up"
                                data-aos-delay="650"
                                target="_blank"
                            >
                                <img
                                    src={join_ico}
                                    alt=""
                                    style={{
                                        marginRight: '12px'
                                    }}
                                />
                                Join our beta program
                            </a>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </>
        )
    }
}
