import $ from 'jquery';
import close from "../../assets/Close_meta.svg";
import '../../css/popup.css';
import { Link } from 'react-router-dom'



function saveCookie() {
    window.localStorage.setItem('accCookie', true)
    closeCookie()
}

function closeCookie() {
    $('.longbar').removeClass('active')
}

function NewPopup() {

    if (!window.localStorage.getItem('accCookie')) {
        $('.longbar').addClass('active')
    }

    return (
        <div className="longbar">
            <img src={close} alt="" onClick={() => closeCookie()} className="cursor" style={{
                position: 'absolute',
                right: '50px',
                top: '10px',
                zIndex: 55,
            }} />
            <div className="t-16-22">
                This website uses cookies to enhance your visit and provide you with information tailored to your interests. For more information on our use of cookies please see our <Link to="/privacy" className='purple' style={{ textDecoration: "underline" }}>Privacy Notice</Link>.
            </div>
            <button
                className="t-14-19 t-700"
                onClick={() => saveCookie()}
                style={{
                    backgroundColor: 'rgba(185, 44, 220, 1)',
                    padding: '14px 32px',
                    borderRadius: '12px'
                }}
            >
                <nobr>I AGREE</nobr>
            </button>
        </div >
    );
}

export default NewPopup;
