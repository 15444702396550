import React, { Component } from "react";
import $ from 'jquery';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import AOS from 'aos';
import 'aos/dist/aos.css';

import '../css/tech.css'
import tech_img1 from "../assets/tech-img1-2.png"
import tech_img2 from "../assets/tech-img2.png"
import tech_img3 from "../assets/new-brain.png"

import circle from "../assets/circle-diagram.png"
import interaction_img from "../assets/interaction-img.png"

import right_btn_arr from "../assets/right-btn-arrow.svg"
import { discordLink } from "../Constants";


export default class Tech extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        AOS.init({
            throttleDelay: 99,
            duration: 700,
            delay: 50,
            once: true,
            anchorPlacement: 'top-bottom',
            useClassNames: true,
        });
    }

    render() {
        return (
            <>
                <Navbar walletAddress={this.props.walletAddress} setWalletAddress={this.props.setWalletAddress}></Navbar>
                <div className="section tech-sec1">
                    <div className="container flex-c h-center">
                        <h1 className="t-center t-700 t-40-52 t-center" data-aos="fade-up" data-aos-delay="50">
                            Bringing AI characters to life
                        </h1>
                        <p className="t-18-25" data-aos="fade-up" data-aos-delay="250">
                            Avatars and digital characters will soon (virtually) walk among us thanks to advancements in computer graphics and CGI technologies in the film and gaming industries and breakthroughs in autonomous animation and conversational AI.
                        </p>
                        <p className="t-18-25" style={{
                            width: '100%'
                        }} data-aos="fade-up" data-aos-delay="450">
                            Digital Dogs™ is a collection of unique AI-powered characters secured as NFTs on the Ethereum blockchain.
                        </p>
                        <p className="t-18-25" data-aos="fade-up" data-aos-delay="650">
                            The NFTs will be stored as ERC-721 tokens and hosted on IPFS. Each NFT is connected through our proprietary PetOS to an actual Digital Dog. A Digital Dog is a 3D model, artificially intelligent digital mind, and various characteristics, such as data, animations, and sounds.
                        </p>

                        <img src={tech_img1} alt="" data-aos="fade-in" data-aos-delay="850" />
                    </div>
                </div>

                <div className="section tech-sec2">
                    <div className="container flex-c h-center">
                        <h1 className="t-center t-700 t-48-57 t-center" data-aos="fade-up" data-aos-delay="50">
                            Inspired by reality
                        </h1>
                        <div className="t-20-28 t-700 white-07" data-aos="fade-up" data-aos-delay="250">
                            The personality and behavior of your Digital Dog will depend on your interactions, just like a real pup.
                        </div>
                        <p className="t-18-25" data-aos="fade-up" data-aos-delay="450">
                            There are many approaches to creating virtual dogs. In games and movies, they use pre-recorded renders, scripted behavior and support simple interactions like ‘pet’ or ‘feed,’ which meets their basic needs. Creating a Digital Dog is a completely different story. We’ve developed AI-powered dogs that can autonomously make decisions and operate animations and sounds.
                        </p>
                        <p className="t-18-25" data-aos="fade-up" data-aos-delay="650">
                            It starts with creating the dog's Al mind, an independent entity, separate from all other Digital Dogs. The interactions you have with your Digital Dog directly impact their personality, gestures, behavior, and interests.
                        </p>
                        <p className="t-18-25" data-aos="fade-up" data-aos-delay="850">
                            Your dog’s artificial mind continues to develop by interacting with other people and their Digital Dogs. The final layer influencing your dog’s development is interactions with the world around them. In particular, experiencing different scenarios, obstacles, and distractions in unknown environments across the metaverse will shape their mind uniquely.
                        </p>

                        <img src={circle} alt="" data-aos="fade-in" data-aos-delay="1050" />

                        <div className="grid">
                            <div className="grid-itm flex-c" data-aos="fade-in" data-aos-delay="50">
                                <span className="t-700 t-20-28">
                                    The Inspiration:
                                </span>
                                <p className="t-18-25">
                                    Can we build a continuous and persistent two-way relationship with a virtual being? How will the interaction feel with a 3D full-body scene compared to a face-only animation? Can a virtual being be our friend, and can they make us happier? What will their social effects be in shared virtual spaces? These are the questions that inspire us.
                                </p>
                            </div>
                            <div className="grid-itm flex-c" data-aos="fade-in" data-aos-delay="250">
                                <span className="t-700 t-20-28">
                                    Problem to solve:
                                </span>
                                <p className="t-18-25">
                                    We’re creating the ultimate companion to accompany you in the metaverse. Our challenge is using expensive and sophisticated software, computing, and networking topology to build an artificially intelligent mind that emulates a dog and engages with its owner autonomously.
                                </p>
                            </div>
                            <div className="grid-itm flex-c" data-aos="fade-in" data-aos-delay="450">
                                <span className="t-700 t-20-28">
                                    The vision:
                                </span>
                                <p className="t-18-25">
                                    Our vision is to create biologically inspired artificial intelligence that honors dogs. We bring Digital Dogs to life by creating artificial minds that autonomously animate themselves and communicate, learn, interpret, and interact with the world around them exactly as dogs do.
                                </p>
                            </div>
                            <div className="grid-itm flex-c" data-aos="fade-in" data-aos-delay="650">
                                <span className="t-700 t-20-28">
                                    The Opportunity :
                                </span>
                                <p className="t-18-25">
                                    People are buying more and more digital fashion, accessories, and art for self-representation in virtual worlds. We believe Digital Dogs are an essential we all need in the metaverse. We see an interesting opportunity catering to this emerging ecosystem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="container flex-c h-center">
                        <h1 className="t-700 t-32-41 t-center" data-aos="fade-up" data-aos-delay="50">
                            The anatomy of a Digital Mind
                        </h1>
                        <img src={tech_img3} alt="" style={{
                            width: '100%'
                        }} data-aos="fade-in" data-aos-delay="250" />
                    </div>
                </div>

                <div className="section" style={{
                    paddingTop: '120px'
                }}>
                    <div className="container flex-r v-center mobile-c">
                        <div className="left" style={{
                            flex: 1
                        }}>
                            <img src={interaction_img} alt="" className="tech-interact-img" />
                        </div>
                        <div className="tech-interact-right">
                            <h2 className="t-32-41 t-700" style={{
                                margin: 'unset',
                                marginBottom: '32px'
                            }} data-aos="fade-up" data-aos-delay="50">
                                Interaction with unknown environments
                            </h2>
                            <p className="t-18-25 white-07" data-aos="fade-up" data-aos-delay="250">
                                Digital Dogs will be used across hundreds of third-party apps and platforms via our SDK. The dogs will autonomously sense and react to the world around them without any previous knowledge about their new environment. Advanced techniques like cloud computing and edge architecture allow us to optimize efficiency and keep the dogs updated across different apps for a complete, continuous experience.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="section tech-sec3">
                    <div className="container flex-c v-center h-center t-center just-space">
                        {/* <div className="wrap"> */}
                        <h1 className="t-700 t-40-52" data-aos="fade-up" data-aos-delay="50">
                            Are you excited?
                        </h1>
                        <p className="t-18-25" data-aos="fade-up" data-aos-delay="250" style={{
                            maxWidth: '600px'
                        }}>
                            Are you interested in learning more? Or have you already decided you want to be a part of our journey? Join our Discord. We will be happy to connect with you.
                        </p>
                        <a
                            data-aos="fade-up"
                            data-aos-delay="450"
                            className="purple-btn hover-btn cursor"
                            href={discordLink}
                            target="_blank"
                        >
                            <span
                                className="t-20-28 t-700"
                            >
                                Join Our Discord
                            </span>
                            <img
                                src={right_btn_arr}
                                alt=""
                            />
                        </a>
                    </div>
                </div>

                <Footer></Footer>
            </>
        )
    }
}
