import React, { Component } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "../css/privacy.css"

export default class Privacy extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Navbar walletAddress={this.props.walletAddress} setWalletAddress={this.props.setWalletAddress} />
                <div
                    className="container"
                    style={{
                        paddingTop: "100px",
                        paddingBottom: "100px"
                    }}
                >
                    <h1>Privacy Notice</h1>
                    <p>Last updated: February 2022</p>
                    <p>We at The Digital Pets Company Ltd. ("<b>Digital Pets</b>", "<b>us</b>", "<b>we</b>", or "<b>our</b>") recognize and respect the importance of maintaining the privacy of visitors to our website ("<b>Site</b>"). This Privacy Notice describes the types of information we collect from you when you visit our Site and/or use the services available thereon, including participating in one of our interactive demos, as further detailed below ("<b>Demo</b>", and collectively "<b>Services</b>"). This Privacy Notice also explains how we process, transfer, store and disclose the information collected, as well as your ability to control certain uses of the collected information. If not otherwise defined herein, capitalized terms have the meaning given to them in the Terms of Service, available at https://thedigitaldogs.co/privacy ("<b>Terms</b>"). "<b>You</b>" means any user of the Site and/or Services.</p>
                    <p>"<b>Personal Data</b>" means any information that refers, is related to, or is associated with an identified or identifiable individual or as otherwise may be defined by applicable law. This Privacy Notice details which Personal Data is collected by us in connection with provision of the Services.</p>
                    <ul>
                        <li><b>Personal Data We Collect.</b> We collect information from you when you choose to use our Site and/or Services. In order to receive related Services, you will be required to provide us with certain Personal Data, as further detailed below.</li>
                        <ul>
                            <li>When you choose to submit a request for a Whitelist spot (as detailed in the Terms), we will collect your email address.</li>
                            <li>When you choose to participate in any of our Demos, we will collect your (nick)name, and subject to your consent for each instance of a Demo use, we will collect your devices’ microphone and camera input.</li>
                            <li>When you choose to contact us with a request or inquiry, we will collect the details you provide in such request or inquiry.</li>
                            <li>In addition, when you use the Site, certain information may be automatically gathered about your computer or mobile device, including non-Personal Data, such as your operating system, and Personal Data such as your IP address, device ID, as well as your use and/or browsing history and any information regarding your use, viewing history on our Site.</li>
                            <li>You are under no legal obligation to provide us with any such Personal Data. It is your voluntary decision whether to provide us with any Personal Data, but if you refuse to provide such information we may not be able to provide you with the Services.</li>
                        </ul>
                        <li><b>How We Use Your Personal Data.</b> We and any of our trusted third-party subcontractors and service providers use the Personal Data we collect from and about you for any of the following purposes: (1) to provide you with the Site and/or Services; (2) to respond to your inquiries or requests, contact and communicate with you; (3) to develop new products or services and conduct analyses to improve our current content, products, and Services; (4) to contact you with informational newsletters and promotional materials relating to our Site and Services; (5) to review the usage and operations of our Site and Services; (6) to prevent fraud, protect the security of our Site and Services, and address any problems with the Site and/or Services and (7) to provide customer support.</li>
                        <li><b>Sharing the Personal Data We Collect.</b> We share your information, including Personal Data, as follows:</li>
                        <ul>
                            <li>
                                Service Providers, and Subcontractors. We disclose information, including Personal Data we collect from and/or about you, to our trusted service providers and subcontractors, who use such information to: (1) help us provide you with the Site and/or Services; and (2) aid in their understanding of how users are using our Site and/or Services.
                                <p>Such service providers and subcontractors provide us with IT and system administration services, data backup, security, and storage services, and data analysis.</p>
                                <p>Our Demos incorporate Microsoft’s "Language Understanding Intelligent Service" ("LUIS"), a conversational AI technology, to enable your voice commands and interactions within our Demos, processed by Microsoft. You can find more information about how Microsoft processes data through LUIS at https://docs.microsoft.com/en-us/legal/cognitive-services/language-service/data-privacy.</p>
                            </li>
                            <li>International Transfers. By accepting this Privacy Notice, you acknowledge that we may use subcontractors and service providers who are located in countries other than your own and may send them information we receive (including Personal Data) and your consent to this.</li>
                            <li>Business Transfers. Your Personal Data may be disclosed as part of, or during negotiations of, any merger, sale of company assets or acquisition (including in cases of liquidation). In such case, your Personal Data shall continue being subject to the provisions of this Privacy Notice.</li>
                            <li>Law Enforcement Related Disclosure. We may share your Personal Data with third parties: (i) if we believe in good faith that disclosure is appropriate to protect our or a third party's rights, property or safety (including the enforcement of the Terms and this Privacy Notice); (ii) when required by law, regulation subpoena, court order or other law enforcement related issues, agencies and/or authorities; or (iii) as is necessary to comply with any legal and/or regulatory obligation.</li>
                            <li>Legal Uses. We may use your Personal Data as required or permitted by any applicable law, for example, to comply with audit and other legal requirements.</li>
                        </ul>
                    </ul>
                    <li><b>Security.</b> We make efforts to follow generally accepted industry standards to protect the Personal Data submitted to and collected by us, both during transmission and once we receive it. However, no method of transmission over the Internet or method of electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security. As the security of information depends in part on the security of the computer you use to communicate with us and the security you use to protect user IDs and passwords, please take appropriate measures to protect this information.</li>
                    <li><b>Your Rights.</b> Subject to applicable law, you may request to see any Personal Data you have provided to us and request that such Personal Data be corrected or updated by emailing us at privacy@thedigitaldogs.co.</li>
                    <li>
                        <b>Data Retention.</b>
                        <ul>
                            <li>Subject to applicable law, we retain Personal Data as necessary for the purposes set forth above. We may delete information from our systems without notice to you once we deem it is no longer necessary for these purposes.</li>
                            <li>In some circumstances, we may store your Personal Data for longer periods of time, for instance where we are required to do so in accordance with legal, regulatory, tax, audit, accounting requirements and so that we have an accurate record of your dealings with us in the event of any complaints or challenges, or if we reasonably believe there is a prospect of litigation relating to your Personal Data or dealings.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Cookies and Similar Technologies.</b> We use cookies and similar technologies for a number of reasons, including to help personalize your experience and to personalize the ads we serve you. When visit our Site, you shall be notified of the use of and placement of cookies and other similar technologies on your device as specified herein.
                        <ul>
                            <li>
                                What are Cookies? A cookie is a small piece of text that is sent to a user's browser or device. The browser provides this piece of text to the device of the originating user when this user returns.
                                <ul>
                                    <li>A "session cookie" is temporary and will remain on your device until you leave the Site.</li>
                                    <li>A "persistent" cookie may be used to help save your settings and customizations across visits. It will remain on your device until you delete it.</li>
                                    <li>First-party cookies are placed by us, while third-party cookies may be placed by a third party. We use both first- and third-party cookies.</li>
                                    <li>We may use the terms "cookies" to refer to all technologies that we may use to store data in your browser or device or that collect information or help us identify you in the manner described above, such as web beacons or "pixel tags".</li>
                                </ul>
                            </li>
                            <li>
                                How We Use Cookies. We use cookies and similar technologies for a number of reasons, as specified below.
                                <div>
                                    <p>The specific names and types of the cookies, web beacons, and other similar technologies we use may change from time to time. However, the cookies we use generally fall into one of the following categories:</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Type of Cookie</th>
                                                <th>Why We Use These Cookies</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Necessary</th>
                                                <th>These cookies are necessary in order to allow the Site to work correctly. They enable you to access the Site, move around, and access different services, features, and tools. Examples include remembering previous actions (e.g. entered text) when navigating back to a page in the same session. These cookies cannot be disabled.</th>
                                            </tr>
                                            <tr>
                                                <th>Functionality</th>
                                                <th>These cookies remember your settings and preferences and the choices you make (such as language or regional preferences) in order to help us personalize your experience and offer you enhanced functionality and content.</th>
                                            </tr>
                                            <tr>
                                                <th>Security</th>
                                                <th>These cookies can help us identify and prevent security risks. They may be used to store your session information to prevent others from changing your password without your login information.</th>
                                            </tr>
                                            <tr>
                                                <th>Performance</th>
                                                <th>These cookies can help us collect information to help us understand how you use our Site, for example whether you have viewed messages or specific pages and how long you spent on each page. This helps us improve the performance of our Site.</th>
                                            </tr>
                                            <tr>
                                                <th>Advertising</th>
                                                <th>These cookies are placed in order to deliver content, including ads relevant and meaningful to you and your interests. They may also be used to deliver targeted advertising or to limit the number of times you see an advertisement. This can help us track how efficient advertising campaigns are, both for our own Services and for other websites. Such cookies may track your browsing habits and activity when visiting our Site and those of third parties.</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                            <li>How to Adjust Your Preferences. Most Web browsers are initially configured to accept cookies, but you can change this setting so your browser either refuses all cookies or informs you when a cookie is being sent. In addition, you are free to delete any existing cookies at any time. Please note that some features of the Services may not function properly when cookies are disabled or removed. For example, if you delete cookies that store your account information or preferences, you will be required to input these each time you visit.</li>
                            <li>By changing your device settings, you can prevent your device's ad identifier being used for interest-based advertising, or you can reset your device's ad identifier. Typically, you can find the ad identifier settings under "privacy" or "ads" in your device's settings, although settings may vary from device to device. Adjusting your preferences as described in this section herein does not mean you will no longer receive advertisements; it only means the advertisements that you do see will be less relevant to your interests.</li>
                        </ul>
                    </li>
                    <li><b>Third-Party Applications and Services.</b> All use of third-party applications or services is at your own risk and subject to such third party's terms and privacy policies.</li>
                    <li><b>Children.</b> We do not knowingly collect Personal Data from children under the age of 16.</li>
                    <li><b>Changes to the Privacy Notice.</b> We may update this Privacy Notice from time to time to keep it up to date with legal requirements and the way we operate our business, and we will place any updates on this webpage. Please come back to this page every now and then to make sure you are familiar with the latest version. If we make material changes to this Privacy Notice, we will seek to inform you by notice on our Site or via email.</li>
                    <li><b>Comments and Questions.</b> If you have any comments or questions about this Privacy Notice or if you wish to exercise any of your legal rights as set out herein, please contact us at privacy@thedigitaldogs.co.</li>
                </div>
                <Footer />
            </div>
        )
    }

}
